import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import useMediaQuery from '@mui/material/useMediaQuery';

import ptBrLocale from 'date-fns/locale/pt-BR';

import { LocalizationProvider, DatePicker } from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"

import { toast, Slide } from 'react-toastify'

import { Grid, TextField, CircularProgress } from "@mui/material";

import CardAnimate from "../../../assests/animates/cardCredits.json"

import { minDate } from "../../../utils/functionTools"

import HLPix from "../../../assests/img/pix-hotline.png"

import { FaUser, FaCheckCircle } from "react-icons/all"

import Lottie from "lottie-react-web"
import Pay from "../../../assests/animates/pay.json"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    boxShadow: 24,
    overflowY: 'scroll',
    maxHeight: '75vh',
    p: 4,
};



export default function Cartao({ 
    open, 
    setOpen, 
    setInvoiceVisable, 
    onCloseAll, 
    Link, 
    loading, 
    createPay
}) {
    const invoice = open
    const handleClose = () => {
        if (steps === '2') {
            onCloseAll()
            setSteps('1')
            return
        }
        setOpen(false);
        setInvoiceVisable(false)
        setSteps('1')

    }

    const [date, setDate] = useState(new Date(minDate()))
    const [cpf, setCpf] = useState('')
    const [name, setName] = useState('')
    const [newPerfil, setNewPerfil] = useState(true)

    const [payersList, setPayersList] = useState([])

    const [selected, setSelected] = useState(null)
    const [canSave, setCansave] = useState(false)

    const [data, setData] = useState({
        name: '',
        cpf: '',
        nascimento: new Date()
    })

    const [steps, setSteps] = useState('1')

    const ToastContent = ({ text, role }) => (
        <>
            <div>
                <div >
                    <h4>{text}</h4>
                </div>
            </div>
            <div>
                <h6 >{role}</h6>
            </div>
        </>
    )

    const Submit = async () => {
        if (name === '' || cpf === '' || date === '' || cpf.length < 11) {
            toast.error(
                <ToastContent text={'Preenha as informações corretamente'} role={'contate o suporte caso o problema persista!'} />,
                { icon: false, transition: Slide, hideProgressBar: false, autoClose: 4000 }
            )
            return
        }

        let Brirthday = date
        if(newPerfil){
            const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
            const day = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString();
            const year = date.getFullYear().toString();
            Brirthday = `${year}-${month}-${day}`
        }
        
        let lis = []

        const oldP = JSON.parse(localStorage.getItem('@rtpayer'))

        if (oldP) {
            lis.push(...oldP)
        }


        const payer = {
            pagador: {
                nome: name,
                cpf: cpf,
                nascimento: Brirthday
            }
        }
        
        if(!payersList || newPerfil){
            lis.push(payer)

        }
        localStorage.setItem('@rtpayer', JSON.stringify(lis));
        createPay('credit_card', [invoice], payer)
    }



    useEffect(() => {
        if (Link) {
            setSteps('2')
        }
    }, [loading, Link, open])


    useEffect(() => {

        const old = localStorage.getItem("@rtpayer")
        if (old) {
            setPayersList(JSON.parse(old))
            setNewPerfil(false)
        }
    }, [open])

    const Step1 = () => {
        return (
            <>
                {
                    payersList && !newPerfil ? <>
                        <Typography variant='h5'>Selecione um perfil para realizar o pagamento</Typography>
                        <Grid container mt={2} xs={12} overflow="hidden">
                            <Grid 
                            item>
                                <Lottie
                                    options={{
                                        animationData: Pay
                                    }}
                                    width={300}
                                />
                            </Grid>
                            <Grid 
                            item 
                            minWidth={550}
                            mt={3} maxHeight={410} overflow="scroll">
                                {
                                    payersList.map((p, i) => (
                                        <Grid
                                            container
                                            mt={2}
                                            xs={12}
                                            bgcolor="#BDBDBD30"
                                            component={Button}
                                            onClick={() => {
                                                const np = {
                                                    nome: p.pagador.nome,
                                                    cpf: p.pagador.cpf,
                                                    nascimento: p.pagador.nascimento
                                                }
                                                if(selected && selected === i + 1){
                                                    setSelected(null)
                                                    return
                                                }
                                                setSelected(i + 1)
                                                setCpf(np.cpf)
                                                setName(np.nome)
                                                setDate(np.nascimento)
                                            }
                                            }
                                            justifyContent="space-between"
                                            direction={"row"}
                                            color="inherit"
                                            sx={{
                                                ":hover": {
                                                    backgroundColor: "inherit"
                                                }
                                            }}
                                        >
                                            <Grid xs={10} container p={2}>
                                                <Grid

                                                    xs={3}
                                                    maxHeight={80}
                                                    borderRadius={1}
                                                    bgcolor="#E2E2E2"
                                                    container
                                                    justifyContent={"center"}
                                                    alignItems="center"
                                                >
                                                    <FaUser size={40} />
                                                </Grid>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    alignItems={"flex-start"}
                                                    justifyContent={"flex-start"}
                                                    xs={7}
                                                    ml={1}>
                                                    <Typography>{p.pagador.nome}</Typography>
                                                    <Typography>{p.pagador.cpf}</Typography>
                                                    <Typography>{p.pagador.nascimento}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid xs={1}>
                                                {selected === i + 1 ? <FaCheckCircle color="#6918A8" size={20}/> : <></>}
                                            </Grid>

                                        </Grid>
                                    )
                                    )
                                }
                            </Grid>

                        </Grid>
                        <Grid xs={12} container justifyContent={"space-between"}>
                        <Button color="error" onClick={handleClose} >cancelar</Button>

                            <Button
                            onClick={() => selected ? Submit() :  setNewPerfil(true)} 
                            variant='contained'
                            disabled={loading}
                            >{selected ? "Confirmar" : "Cadastrar Novo"}</Button>
                        </Grid>
                    </> :
                        invoice && invoice.cobranca_id ?
                            <>
                                <Typography variant='h4' component={'h2'}>
                                    PREENCHA AS INFORAMAÇÕES PARA CONFIRMAR
                                </Typography>
                                <Grid mt={2} xs={12} container>
                                    <Grid xs={4} borderRadius={2} p={1} bgcolor="#F5F5F5">
                                        <Lottie
                                            options={{
                                                animationData: Pay,
                                                loop: false
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs={8} p={1}>
                                        <form onSubmit={Submit}>
                                            <TextField
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                                fullWidth
                                                type={"text"}
                                                variant='standard'
                                                label="Nome completo" />

                                            <TextField
                                                value={cpf}
                                                onChange={e => setCpf(e.target.value)}
                                                sx={{ marginTop: 2 }}
                                                fullWidth
                                                type={"number"}
                                                variant='standard'

                                                label='Cpf' />

                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
                                                <DatePicker

                                                    label="Nascimento"
                                                    value={date}
                                                    minDate={new Date("1930-01-01")}
                                                    maxDate={new Date(minDate())}
                                                    views={['day', 'month', 'year']}
                                                    onChange={(newValue, a) => {
                                                        setDate(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} type="date" variant='standard' fullWidth sx={{ marginTop: 2 }} />}
                                                />
                                            </LocalizationProvider>
                                        </form>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} justifyContent="space-between" container>
                                    <Button color="error" onClick={handleClose} >cancelar</Button>
                                    <Grid>
                                        {
                                            payersList.length > 0 &&
                                            <Button
                                                sx={{ mr: 2 }}
                                                onClick={() => setNewPerfil(false)}
                                            >{ "Selecionar perfil"}</Button>
                                        }
                                        <Button style={{ width: 150 }} variant="contained" disabled={loading} onClick={Submit}>
                                            {
                                                loading ? <CircularProgress size={15} /> : "Confirmar"
                                            }
                                        </Button>
                                    </Grid>

                                </Grid>
                            </>
                            : <></>
                }
            </>
        )
    }


    const Step2 = () => {
        return (
            <Grid xs={12}>
                <Typography
                    style={{ marginBottom: 25 }}
                    variant='h4' color="#2A005D"
                    component={'h2'}>
                    PREENCHA AS INFORAMAÇÕES E CONFIRME O PAGAMENTO
                </Typography>

                <iframe src={Link}
                    style={{
                        height: '70vh',
                        width: '100%',
                        zIndex: 1,
                        borderRadius: 10,
                        border: 'none'
                    }} />

                <Grid xs={12} mt={2} justifyContent="space-between" container>
                    <Button color="primary" sx={{ width: 200 }} variant='contained' onClick={handleClose} >FECHA</Button>
                </Grid>
            </Grid>
        )
    }



    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {
                    (() => {
                        switch (steps) {
                            case '1':
                                return Step1()
                                break;
                            case '2':
                                return Step2()
                                break;
                            default:
                                break;
                        }
                    })()
                }
            </Box>
        </Modal>
    );
}