import React, { useState, useEffect } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    Dialog,
    DialogTitle
} from "@mui/material"

import { RemoteServices } from "../../services"

import Toast from "../../components/toast"

export default function ControlledAccordions({ setNewUser, setChangePass }) {

    const [expanded, setExpanded] = useState(false);

    const [data, setData] = useState([])
    const [prevValues, setPrevValues] = useState([]);
    const [senha, setSenha] = useState();

    const [delUser, setDelUser] = useState(null)

    const [registersIDs, setRegistersIDs] = useState([]);
    const [canSend, setCanSend] = useState(true);

    const [loading, setLoading] = useState(false);



    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };





    function saveChangesInSalesman(salesman, index) {

        setLoading(3)
        if (salesman.password && salesman.password.length < 6) {
            setLoading(3);
            return
        }
        if (!salesman.email.includes('@')) {
            setLoading(3)
            return
        }
        setCanSend(false);

        const data =
        {
            ...((salesman.email) ? { email: salesman.email } : {}),
            ...((senha && senha > 5) ? { senha: senha } : {}),
            fv_vendedor: {
                ver_contas_clientes: salesman.ver_contas_clientes,
                pode_alterar_preco: salesman.pode_alterar_preco,
                ativo: salesman.ativo,
                local_id: salesman.local_id,
                nome: salesman.nome,
            }

        }
        RemoteServices.user.updateUser({ user_id: salesman.id, data: data }).then((response) => {

            if (response.error) {
                Toast({
                    textMain: 'Não foi possivel atualizar os dados',
                    subText: 'se o problema persistir contate o suporte!',
                    type: 'error'
                })
                return
            }
            Toast({ textMain: 'Dados atualizados', subText: 'dados do usuário foram atualizados!' })
            setCanSend(true);
            setPrevValues(salesman);
            editSalesman(index, 'password', '')
        })
            .catch(reason => {
                if (reason.response?.status === 408 || reason.response?.status === 400) {
                }
                setCanSend(true);
            })
            .finally(() => setLoading(false))
    }



    function getData() {
        RemoteServices.user.requireUsersList({ perfil: 'fv_vendedor' }).then(response => {
            setData(response.usuarios)
            if (response) {
                setRegistersIDs(response.usuarios.map((a) => a.local_id));
                setPrevValues(response.usuarios);
            }
            if (response.severity) {
                showSnack(r.severity, 'error')
            }
        })
    }


    function editSalesman(key, field, value) {
        setData(data.map((u, i) => {
            if (key === i) {
                return { ...u, [field]: value };
            }
            return u;
        }))
    }


    useEffect(() => {
        getData()
    }, [])


    return (
        <Grid
            bgcolor={"background.paper"}
            p={2}
            borderRadius={2}
        >
            {
                data.map((u, index) => (
                    <Accordion expanded={expanded === u.local_id} onChange={handleChange(u.local_id)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                {u && u.nome}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid
                                container
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                xs={12}>
                                <Typography component={"span"} variant="h4">Id local - {u && u.local_id}</Typography>
                                <Grid xs={10}>
                                    <TextField
                                        sx={{ mt: 2 }}
                                        value={u && u.nome}
                                        onChange={e => editSalesman(index, 'nome', e.target.value)}
                                        fullWidth label="Nome do usuário" />
                                </Grid>
                            </Grid>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth label="E-mail"
                                value={u && u.email}
                                onChange={e => editSalesman(index, 'email', e.target.value)}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth label="Nova senha"
                                onChange={e => setSenha(e.target.value)}
                            />
                            <FormControlLabel
                                onChange={() => editSalesman(index, 'ativo', !u.ativo)}
                                control={<Checkbox checked={u && u.ativo} />}
                                label="Usuário ativo"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={u && u.ver_contas_clientes} />}
                                label="Visualizar contas a receber"
                                onChange={() => {
                                    editSalesman(index, 'ver_contas_clientes', !u.ver_contas_clientes)
                                }}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={u && u.pode_alterar_preco} />}
                                onChange={() => {
                                    editSalesman(index, 'pode_alterar_preco', !u.pode_alterar_preco)
                                }}
                                label="Alterar preço" />
                            <Grid
                                container
                                mt={2}>
                                <Button
                                    color="error"
                                    onClick={() => setDelUser(u)}
                                >
                                    Remover usuário
                                </Button>
                                <Button
                                    onClick={() => saveChangesInSalesman(u, index)}
                                    disabled={loading}
                                    variant="contained"
                                    sx={{ ml: 2 }}
                                >
                                    Salvar
                                </Button>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))
            }
            <Button
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => setNewUser(registersIDs)}
                variant="contained">Cadastrar novo usuário</Button>
            <Button fullWidth sx={{ mt: 2 }} onClick={() => setChangePass(true)}>Alterar Senha</Button>
            <Delete data={delUser} handleClose={() => setDelUser(null)} />
        </Grid>
    );
}



const Delete = ({ data, handleClose }) => {

    const [loading, setLoading] = useState(false)

    function deleteUser(userID) {
        setLoading(true)
        RemoteServices.apiPrev.DeleteUser(userID).then(() => {
            Toast({
                textMain: 'Usuario deletado!'
            })

        })
            .catch(() =>
                Toast({
                    textMain: 'Não foi possivel deletar usuario!',
                    subText: 'se o problema persistir contate o suporte!',
                    type: 'error'
                })
            ).
            finally(() => setLoading(false))
    }




    return (
        <Dialog
            onClose={handleClose}
            open={data}
        >
            <DialogTitle>
                <Typography sx={{ textAlign: 'center' }}>DELETAR USUÀRIO "{data && data.nome}"? </Typography>
            </DialogTitle>

            <Grid xs={12}
                container
                justifyContent={"center"}
                alignItems={"center"}
                p={1}>
                <Typography sx={{ textAlign: 'center', pb: 1 }}>
                    Você realmente quer deletar o usuário "{data && data.nome}"? Você poderá adicioná-lo novamente mais tarde.
                </Typography>
                <Button
                    onClick={handleClose}
                    color="error"
                    sx={{ width: 155 }}
                >Cancelar</Button>
                <Button
                    disabled={loading}
                    color="error"
                    variant="contained"
                    sx={{ width: 155 }}
                    onClick={() => deleteUser(data.id)}
                >Deletar</Button>
            </Grid>
        </Dialog>
    )
}