import React, { useState, useEffect } from 'react';

import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    Grid,
    CircularProgress
} from "@mui/material"

import { getLegibleDate } from "../../../utils/functionTools"
import { RemoteServices } from "../../../services"

import { toast, Slide } from 'react-toastify'

import Toast from "../../../components/toast"

const notAuthorized = 'este campo não pode ser alterado!'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: 2,

};

const initialValues = {
    nome_fantasia: '',
    razao_social: '',
    cpf_cnpj: '',
    email: '',
    telefone: '',
    cidade: '',
    uf: '',
    registrado_em: '',
    status: ''
}

export default function InfosClient({ client, setClient }) {
    const handleClose = () => { setClient(false); setData({}) };


    const [data, setData] = useState(initialValues)
    const [dataPrev,setDataPrev] = useState(initialValues)
    const [loading, setLoading] = useState(false)


    const ToastContent = ({ text, role }) => (
        <>
            <div>
                <div >
                    <h4>{text}</h4>
                </div>
            </div>
            <div>
                <h6 >{role}</h6>
            </div>
        </>
    )


    const updateUser = async (store) => {
        setLoading(true)


        await RemoteServices.user.updateUser({
            user_id: store.id, data: {
                email: store.email,
                pessoa: {
                    razao_social: store.razao_social,
                    nome_fantasia: store.nome_fantasia,
                    ...(store.telefone && { telefone: store.telefone })
                    // status: store.ativo
                }
            }
        })
            .then((e) => {
                if (e.error) {
                    toast.error(
                        <ToastContent
                            text={'Não foi possivel atualizar informações'}
                            role={'Contate o suporte caso o problema persista!'}
                        />,
                        { icon: false, transition: Slide, hideProgressBar: false, autoClose: 4000 }
                    )
                    return
                }
                toast.success(
                    <ToastContent
                        text={'informações atualizadas'}
                        role={'informações do cliente foram atualizadas com sucesso'}
                    />,
                    { icon: false, transition: Slide, hideProgressBar: false, autoClose: 4000 }
                )
            }).catch(() => {
            }).finally(() => {
                setLoading(false)
            })
    }


    useEffect(() => {
        if (client) {
            setData(client)
            setDataPrev(client)
        }
    }, [client])

    return (
        <Modal
            open={client}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={style}>
                <Typography
                    id="modal-modal-title"
                    variant="h3"
                    component="h1">
                    {data.nome_fantasia}
                </Typography>

                <Grid container mt={2} xs={12} spacing={2}>
                    <Grid container justifyContent={"center"} spacing={2} p={2} xs={12}>
                            <TextField
                                sx={{ maxWidth: 325, m: 1  }}
                                id="outlined-basic"
                                label="Fantasia"
                                variant="outlined"
                                value={data.nome_fantasia}
                                onChange={e => setData({ ...data, nome_fantasia: e.target.value })}
                            />
                            <TextField
                                sx={{ maxWidth: 325, m: 1}}
                                id="outlined-basic"
                                label="Razão social"
                                variant="outlined"
                                value={data.razao_social}
                                onChange={e => setData({ ...data, razao_social: e.target.value })}

                            />
                            <TextField
                                sx={{ maxWidth: 325, m: 1}}
                                id="outlined-basic"
                                label="E-mail"
                                variant="outlined"
                                onChange={e => setData({ ...data, email: e.target.value })}
                                value={data.email}
                            />
                            <TextField
                                sx={{ maxWidth: 325, m: 1}}
                                id="outlined-basic"
                                label="Cpf/Cnpj"
                                variant="outlined"
                                onChange={() => Toast({ textMain: notAuthorized , type: 'error' })}
                                value={data.cpf_cnpj}
                            />
                            <TextField
                                sx={{ maxWidth: 325, m: 1}}
                                id="outlined-basic"
                                label="Cidade"
                                variant="outlined"
                                onChange={() => Toast({ textMain: notAuthorized , type: 'error' })}
                                value={data.cidade}
                            />
                            <TextField
                            sx={{ maxWidth: 325, m: 1}}
                                id="outlined-basic"
                                label="Uf"
                                variant="outlined"
                                onChange={() => Toast({ textMain: notAuthorized , type: 'error' })}
                                value={data.uf}
                            />
                            <TextField
                                sx={{ maxWidth: 325, m: 1}}
                                id="outlined-basic"
                                label="Telefone"
                                variant="outlined"
                                onChange={e => setData({ ...data, telefone: e.target.value })}
                                value={data.telefone}
                            />
                            <TextField
                                sx={{ maxWidth: 325, m: 1}}
                                id="outlined-basic"
                                label="Registro"
                                variant="outlined"
                                onChange={() => Toast({ textMain: notAuthorized , type: 'error' })}
                                value={data ? getLegibleDate(new Date(data.registrado_em)) : ''}
                            />
                            <TextField
                                sx={{ maxWidth: 325, m: 1}}
                                id="outlined-basic"
                                label="Status"
                                variant="outlined"
                                onChange={() => Toast({ textMain: notAuthorized , type: 'error' })}
                                value={data.status}
                            />
                    </Grid>
                    <Grid
                        mt={2}
                        container
                        justifyContent={"space-between"}
                        direction="row"
                        xs={12}>
                        <Button
                            onClick={handleClose}
                            color="error">Cancelar</Button>
                        <Button
                            style={{ width: 150 }}
                            variant="contained"
                            disabled={loading || data === dataPrev}
                            onClick={() => updateUser(data)}>
                            {
                                loading ? <CircularProgress size={15} /> : "Salvar"
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}