const { default: gateway } = require("../gateway")

export const registerUser = async (data, profile) => {
    try {
        const response = await gateway.post(`auth/register/${profile}`, data);
        return response.data;
    } catch(reason) {
        if (reason.response) {
            const {data, status} = reason.response;

            if (status === 409) {
                data.severity = 'warning';
            } else {
                data.severity = 'error';
            }

            return data;
        }
        return {
            error: "Problemas ao se conectar com o serviço",
            severity: 'error'
        }
    }
}