import PropTypes from 'prop-types';

import React, { useEffect, useState } from "react"

import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
  Menu,
  MenuItem
} from '@mui/material';

import Iconify from '../../Iconify';


const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));


UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  loading,
  filterItems,
  filterSelected,
  placeholder,
  noSearch,
  filter
}) {


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };


  const [filterIn, setFilterIn] = useState(filter ? filter : '')

  const HandleSelect = (e) => {
    setAnchorEl(null);
    setFilterIn(e)
    filterSelected && filterSelected(e)
 }


  // useEffect(() => {
  //   filterSelected && filterSelected(filter)
  // }, [])


  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : !noSearch ? (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder={placeholder ? placeholder : 'pesquisar...'}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      ) : <></>}
      {loading && (<div className='loading-text'> Carregando</div>)}
      {filterItems && (
        <>
          <Tooltip title="Filter list">
            <IconButton
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <span
                style={{
                  fontSize: 14
                }}
              >{filterIn && filterIn}</span>
              <Iconify icon="ic:round-filter-list" />
            </IconButton>
          </Tooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            {
              filterItems.map((value, i) => (
                <MenuItem
                  selected={value === filterIn}
                  onClick={() => HandleSelect(value)}
                  key={`${value}-${i}`}>{value}</MenuItem>
              ))
            }
          </Menu>
        </>
      )}

    </RootStyle >
  );
}
