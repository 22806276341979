import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

import PageNotfound from "../pages/Page404"


const Page404 = {
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
        {
            path: '/404',
            element:  <PageNotfound />
        },
        { 
            path: '*', 
            element: <Navigate to="/404" />
        },
    ]
};

export default Page404;
