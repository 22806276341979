import { useEffect, useState } from 'react';

import { FaBarcode } from "react-icons/all"

import {
    Card,
    Grid,
    Typography,
    TablePagination,
    Divider,
    Button
} from '@mui/material';
// components

import "./style.css"

import Scrollbar from '../Scrollbar';
import { UserListToolbar } from '../_dashboard/user';
import FreeMobileImg from '../../assests/img/free.png';

import { getLegibleDate, toCashBR, cnpjMask, cpfMask } from "../../utils/functionTools";


export default function TableInvoice({
    data,
    count,
    setPage,
    page,
    setInvoice,
    setInfos,
    pay,
    loadingPay,
    setFilter,
    filter
}) {

    const [selected, setSelected] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [msgFree, setMsgFree] = useState(true);
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState(null)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    useEffect(() => {
        const localMsg = localStorage.getItem("msg-app-free")
        if (localMsg) {
            setMsgFree(JSON.parse(localMsg).msg)
        }
    }, [msgFree])

    return (
        <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '700px', flexDirection: 'column', overflowX: 'auto' }} >
            <div style={{ position: 'relative', }}>
                <div class="morphing-blob-wrapper" style={{ marginLeft: -58 }} data-v-34423847="" data-v-132740da="">
                    <div class="blob-inner levitate" data-v-34423847="">
                        <svg class="organic-blob" width="300" height="300" xmlns="http://www.w3.org/2000/svg" data-v-34423847="">
                            <defs data-v-34423847="">
                                <filter class="goo" data-v-34423847="">
                                    <feGaussianBlur in="SourceGraphic" stdDeviation="10" data-v-34423847="">
                                    </feGaussianBlur>
                                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" data-v-34423847="">
                                    </feColorMatrix><feComposite in="SourceGraphic" in2="goo" operator="atop" data-v-34423847="">
                                    </feComposite>
                                </filter>
                            </defs>
                            <circle fill={'#8626D1'} class="circle-1" cx="150" cy="145" r="100" data-v-34423847="">
                            </circle>
                            <circle fill={'#8626D1'} class="circle-2" cx="150" cy="155" r="100" data-v-34423847="">
                            </circle>
                            <circle fill={'#8626D1'} class="circle-3" cx="145" cy="150" r="100" data-v-34423847="">
                            </circle>
                            <circle fill={'#8626D1'} class="circle-4" cx="155" cy="150" r="100" data-v-34423847="">
                            </circle>
                        </svg>
                    </div>
                </div>
                <img src={FreeMobileImg} style={{ maxWidth: 300 }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 420, marginTop: 75, }}>
                <Typography sx={{ mt: 3 }} className='loading-text' variant='h5'>
                    100% gratuito
                </Typography>
                <Typography sx={{ mt: 3 }}>
                    As cobranças pelo aplicativo "Força de Vendas" estão, temporariamente, suspensas, pois estamos trabalhando para atende-los com um aplicativo totalmente reformulado.
                    Fique à vontade para usufruir, gratuitamente, da versão durante este período.
                    Avisaremos aos parceiros, com a devida antecedência, ao retornarmos com a cobrança.
                </Typography>
            </div>
            {/* <Button onClick={() => {setMsgFree(!msgFree); localStorage.setItem("msg-app-free", JSON.stringify({ msg: !msgFree }))}} sx={{ mt: 1 }}>
                Visualizar cobranças anteriores
            </Button> */}
        </Card>
    )


    return (
        <></>
        // <Card >
        //     <UserListToolbar
        //         numSelected={selected.length}
        //         filterName={filterName}
        //         onFilterName={handleFilterByName}
        //         noSearch
        //         filterSelected={e => setFilter(e)}
        //         filterItems={['TODAS', 'PAGA', 'ABERTA', 'NAO PAGA', 'CANCELADA']}
        //         filter={filter}
        //     />

        //     <Scrollbar>
        //         <Grid conteiner justifyContent={'center'} alignItems="center" p={3}>
        //             {
        //                 data && data.length > 0 && data.map((e) => (
        //                     <Grid
        //                         p={2}
        //                         bgcolor="#fff"
        //                         borderRadius={1}
        //                         container
        //                         alignItems={'center'}
        //                         direction={"row"}
        //                         justifyContent="space-around"
        //                         onClick={() => {
        //                             setId(e.pagamento_id)
        //                             e.status === 'ABERTA' || e.status === 'NAO PAGA' ? setInvoice(e) : e.status === 'ACHATADO' ? pay(e.pagamento_id) : setInfos(e)
        //                         }}
        //                         sx={{
        //                             boxShadow: 'box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;',
        //                             margin: '10px 0px 10px 0px',
        //                             transition: '.5s ease-in-out',
        //                             ":hover": {
        //                                 cursor: "pointer",
        //                                 transform: 'scale(1.03)',
        //                                 boxShadow: '#8626D130 0px 5px 15px'
        //                             }
        //                         }}
        //                     >

        //                         <Grid item>
        //                             <FaBarcode size={50} />
        //                         </Grid>
        //                         <Grid ml={5} item xs={10} display={'flex'} direction="column">
        //                             <Grid container direction={"row"} mb={1} xs={12}>
        //                                 <Grid
        //                                     container
        //                                     xs={6}
        //                                     sx={{
        //                                         borderRight: 'solid 1px #fff'
        //                                     }}
        //                                     direction="row"
        //                                 >
        //                                     <Typography variant='subtitle2'>
        //                                         vencimento:
        //                                     </Typography>
        //                                     <Typography sx={{
        //                                         marginLeft: 2
        //                                     }} variant='subtitle1'>
        //                                         {e && getLegibleDate(new Date(e.vence_em))}
        //                                     </Typography>
        //                                 </Grid>
        //                                 <Grid container justifyContent={"flex-end"} xs={6}>
        //                                     <Grid display="flex" direction="row">
        //                                         {loadingPay && id === e.pagamento_id && <Typography variant="subtitle1" className='loading-text'>Carregando</Typography>}
        //                                         <Typography variant='subtitle2'>
        //                                             Valor:
        //                                         </Typography>
        //                                         <Grid display="flex" direction="row">
        //                                             <Typography ml={1} variant='subtitle2'>
        //                                                 {e && toCashBR(e.valor ? e.valor : 0)}
        //                                             </Typography>
        //                                         </Grid>

        //                                     </Grid>
        //                                 </Grid>
        //                             </Grid>
        //                             {/* ---------------------------- */}
        //                             <Divider />
        //                             {/* ---------------------------- */}

        //                             <Grid mt={1} xs={12} container direction={"row"}>
        //                                 <Grid container direction={"row"} xs={6}
        //                                     sx={{
        //                                         borderRight: 'solid 1px #fff'
        //                                     }}
        //                                 >
        //                                     <Typography variant='subtitle2'>
        //                                         {e && e.cobranca_id}
        //                                     </Typography>
        //                                     <Typography ml={1} variant='subtitle2'>
        //                                         -
        //                                     </Typography>
        //                                     <Typography ml={1} variant='subtitle2'>
        //                                         {e && e.loja_fantasia}
        //                                     </Typography>
        //                                     <Typography ml={1} variant='subtitle2'>
        //                                         -
        //                                     </Typography>
        //                                     <Typography ml={1} variant='subtitle1'>
        //                                         {e && e.revenda_cpf_cnpj.length > 11 ? cnpjMask(e.revenda_cpf_cnpj) : cpfMask(e.revenda_cpf_cnpj)}
        //                                     </Typography>
        //                                 </Grid>
        //                                 <Grid container justifyContent={"flex-end"} xs={6}>
        //                                     <Grid p={1} >
        //                                         <Typography ml={1} sx={{
        //                                             backgroundColor:
        //                                                 e.status === 'ABERTA' ? '#D8700F30' :
        //                                                     e.status === 'ACHATADO' ? '#17BA1730'
        //                                                         : e.status === 'CANCELADA' || e.status === 'NAO PAGA' ? '#D80F4C30' : '#8626D130',
        //                                             borderRadius: 1,
        //                                             padding: '3px',
        //                                             color: e.status === 'ABERTA' ? '#D8700F' :
        //                                                 e.status === 'ACHATADO' ? '#17BA17'
        //                                                     : e.status === 'CANCELADA' || e.status === 'NAO PAGA' ? '#D80F4C' : '#8626D1'
        //                                         }} variant='subtitle1'>
        //                                             {e && e.status === 'ACHATADO' ? 'AGUADANDO PAGAMENTO' : e.status}
        //                                         </Typography>
        //                                     </Grid>
        //                                 </Grid>
        //                             </Grid>
        //                         </Grid>
        //                     </Grid>
        //                 ))
        //             }

        //         </Grid>
        //     </Scrollbar>

        //     <TablePagination
        //         component="div"
        //         count={count}
        //         page={page}
        //         rowsPerPage={5}
        //         rowsPerPageOptions={[5, 10]}
        //         onPageChange={handleChangePage}
        //         labelRowsPerPage='itens por pagina'
        //         onRowsPerPageChange={handleChangeRowsPerPage}
        //     />
        // </Card>
    );
}
