const { default: gateway } = require("../gateway")

export const requireUsersCobrancas = async ({page, limit, revenda, status, ano, loja, ordenar_por, order_mode}) => {
    try {
        const response = await gateway.get('/finance/cobrancas',{
            params: {
                page: page && page,
                limit: limit && limit,
                revenda: revenda && revenda,
                status: status ? status : null,
                ano: ano && ano,
                loja: loja && loja,
                ordenar_por: ordenar_por && ordenar_por,
                order_mode: order_mode && order_mode,
            }
        });
        return response.data;
    } catch(reason) {
        if (reason.response) {
            const { data } = reason.response;
            data.severity = 'error';

            return data;
        }
        return {
            error: "Problemas ao se conectar com o serviço",
            severity: 'error'
        }
    }
}