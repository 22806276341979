import axios from "axios";

const gateway = axios.create({
    baseURL: window.ENV.API_URL,
});


export const gatewayPrev = axios.create({
    baseURL: window.ENV.API_PREV,
});

async function intercepter(config) {
    const token = localStorage.getItem('@rttoken');
    if(token){
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}

gateway.interceptors.request.use(intercepter);
gateway.interceptors.response.use(e => e, async reject => {
    if (reject.response?.status === 401) {
        const token = localStorage.getItem('@rttoken');
        if (token) {
            localStorage.removeItem('@rttoken');
            localStorage.removeItem('@rtdata');
            document.location.reload();
        }
    }
    return Promise.reject(reject);
})
gatewayPrev.interceptors.request.use(intercepter);
gatewayPrev.interceptors.response.use(e => e, async reject => {
    if (reject.response?.status === 401) {
        const token = localStorage.getItem('@rttoken');
        if (token) {
            localStorage.removeItem('@pdtet');
            localStorage.removeItem('@rtdata');
            // document.location.reload();
        }
    }
    return Promise.reject(reject);
})

export default gateway;
