import MoneyTicketPage from "../pages/admin"
import DownloadsPage from "../pages/resales/donwloads"
import ClientsPage from "../pages/admin/clients"
import PaymentsPage from "../pages/admin/payments"
import DashboardLayout from '../layouts/dashboard'
import { Navigate } from 'react-router-dom'

const ResalesRoutes = {
    path: '/',
    element: <DashboardLayout />,
    children: [
        { 
            path: '/', 
            element: <Navigate to="/dashboard/admin" />
        },
        {
            path: '/dashboard/admin',
            element: <MoneyTicketPage />
        },
        {
            path: '/dashboard/downloads',
            element: <DownloadsPage />
        },
        {
            path: '/dashboard/Clientes',
            element: <ClientsPage />
        },
        {
            path: '/dashboard/Payments',
            element: <PaymentsPage />
        },
        
    ]};

export default ResalesRoutes;
