import React, { useEffect, useState } from "react"
import {
    Modal,
    Box,
    Typography,
    Grid,
    Button,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    TextField,
    FormControlLabel,
    Checkbox,
    CircularProgress
} from "@mui/material"

import Lottie from "lottie-react-web"
import noConnection from "../../assests/animates/noConnection.json"
import connecting from "../../assests/animates/connect.json"

import { RemoteServices } from "../../services"

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Toast from "../../components/toast"

const NewUser = ({ newId, close }) => {

    const [loading, setLoading] = useState(false)
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [usersToAdd, setUsersToAdd] = useState([]);
    const [erro, setErro] = useState(false)

    const [expanded, setExpanded] = useState(false);

    const HandleClose = () => {
        setErro(false);
        close(false)
    }

    const Send = () => {
        setLoading(true)

        const send = usersToAdd.filter(a => a.email);

        const users = send.map((e) => {
            return {
                email: e.email,
                senha: e.password,
                nome: e.name,
                local_id: e.localID,
                forca_venda: {
                    ver_contas_clientes: e.ver_contas_clientes ? true : false,
                    pode_alterar_preco: e.pode_alterar_preco ? true : false
                }
            }
        })

        if (usersToAdd.length > 0) {
            RemoteServices.user.registerUserLoja({
                usuarios: { usuarios: users }, perfil: 'fv_vendedor'
            })
                .then(e => {
                    if (e.error) {
                        Toast({
                            textMain: e.error,
                            subText: 'se o problema persiste contate o suporte!',
                            type: 'error'
                        })
                        return
                    }
                    Toast({ textMain: 'Usuário cadastrado', subText: 'Usuário ja está apto para utilizar o serviço!' })
                })
                .catch(e => {

                })
                .finally(() => setLoading(false))
        }
    }



    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function editSalesman(key, field, value) {
        setUsersToAdd(usersToAdd.map((u, i) => {
            if (key === i) {
                return { ...u, [field]: value };
            }
            return u;
        }))
    }

    const getUsers = () => {
        setLoadingUsers(true)
        RemoteServices.apiPrev.Teste(newId).then(r => {
            const { response } = r;
            if (r.error) {
                setErro(true)
            }
            if (response) {
                setUsersToAdd(response.users.map((u) => {
                    return { ...u, email: '', password: '123456', ver_contas_clientes: true, pode_alterar_preco: true }
                }) ?? []);
            }
        }).catch(() => {
            setLoadingUsers(false)
        }).finally(() => setLoadingUsers(false))
    }

    
    useEffect(() => {
        if (newId) {
            getUsers()
        }
    }, [newId])



    return (
        <Modal
            onClose={HandleClose}
            open={newId}>
            <Box sx={style}>
                {
                    loadingUsers ?
                        <>
                            <Lottie
                                options={{
                                    animationData: connecting
                                }}
                                width={300}
                            />
                            <Typography 
                            color="purple"
                            sx={{
                                fontSize: 17,
                                textAlign: 'center'
                            }}>
                                conectando-se com o serviço...
                            </Typography>
                        </>
                        :

                        erro ? <>
                            <Lottie
                                options={{
                                    animationData: noConnection
                                }}
                                width={300}
                            />
                            <Grid sx={12}>
                                <Typography sx={{
                                    fontSize: 17
                                }}>
                                    Não foi possível obter os usuários cadastrados no Host.
                                </Typography>
                                <Typography sx={{
                                    fontSize: 17
                                }}>
                                    verifique se o serviço "host_evendas" está em execução!
                                </Typography>
                            </Grid>
                            <Button
                                fullWidth
                                onClick={HandleClose}
                                sx={{
                                    mt: 2
                                }}
                                variant="contained">Entendi</Button>
                        </>
                            :
                            <Grid>
                                {
                                    usersToAdd.map((u, index) => (
                                        <Accordion expanded={expanded === u.localID} onChange={handleChange(u.localID)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                    {u && u.name}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography component={"span"} variant="h4">{u && u.name} - {u && u.localID}</Typography>

                                                <TextField
                                                    sx={{ mt: 2 }}
                                                    value={u && u.email}
                                                    onChange={e => editSalesman(index, 'email', e.target.value)}
                                                    fullWidth label="E-mail do usuário" />
                                                <TextField
                                                    sx={{ mt: 2 }}
                                                    value={u && u.password}
                                                    type={'password'}
                                                    onChange={e => editSalesman(index, 'password', e.target.value)}
                                                    fullWidth label="Senha" />
                                                <FormControlLabel
                                                    control={<Checkbox checked={u && u.ver_contas_clientes} />}
                                                    label="Visualizar contas a receber"
                                                    onChange={() => {
                                                        editSalesman(index, 'ver_contas_clientes', !u.ver_contas_clientes)
                                                    }}
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={u && u.pode_alterar_preco} />}
                                                    onChange={() => {
                                                        editSalesman(index, 'pode_alterar_preco', !u.pode_alterar_preco)
                                                    }}
                                                    label="Alterar preço" />
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                }
                            </Grid>
                }
                {
                    !erro && !loadingUsers && <>
                        <Button
                            disabled={loading}
                            variant="contained"
                            onClick={Send}
                            fullWidth>
                            {loading ? <CircularProgress size={17} /> : 'Salvar'}
                        </Button>
                        <Button color="error" fullWidth onClick={HandleClose}>Cancelar</Button>
                    </>
                }

            </Box>
        </Modal>
    )
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    display: 'flex',
    minWidth: 200,
    flexDirection: 'column',
    border: 'none',
    boxShadow: 24,
    maxHeight: '75vh',
    p: 4,
};

export default NewUser