import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';

import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';

import useAuth from "../context/auth"

import USERLIST from '../_mocks_/user';

import { RemoteServices } from "../services";

import { getLegibleDate } from "../utils/functionTools"


const TABLE_HEAD = [
  { id: 'name', label: 'Fantasia', alignRight: false },
  { id: 'company', label: 'Cpf/Cnpj', alignRight: false },
  { id: 'role', label: 'Registro', alignRight: false },
  { id: 'email', label: 'E-mail', alignRight: false },
  { id: 'registrado_em', label: 'Situação', alignRight: false },
  { id: '' }
];



export default function User({ setInfos, setNewClient, perfil }) {

  const { user } = useAuth()

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsperpage = (event, newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage.props.value)
  }

  const [count, setCount] = useState(0)
  const [data, setData] = useState([])


  useEffect(() => {
    setLoading(true)
    RemoteServices.user.requireUsersList({
      app: 1,
      perfil: perfil ? perfil : "loja",
      limit: rowsPerPage,
      page: page + 1,
      fragment: filterName
    })
      .then(response => {
        if (response.error) {
          return
        }
        setData(response.usuarios)
        setCount(response.contagem)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, rowsPerPage, filterName])


  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Clientes força de vendas
        </Typography>
        {
          user.perfil !== 'admin' && (
            <Button
              variant="contained"
              onClick={() => setNewClient(true)}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              cadastra novo cliente
            </Button>
          )
        }

      </Stack>

      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          loading={loading}
          onFilterName={e => setFilterName(e.target.value)}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={count}
                numSelected={selected.length}
              />
              <TableBody>
                {data.length > 0 && data.map((row) => {
                  const isItemSelected = selected.indexOf(row.nome_fantasia) !== -1;

                  return (
                    <TableRow
                      hover
                      sx={{
                        ":hover": {
                          cursor: 'pointer'
                        }
                      }}
                      onClick={() => setInfos(row)}
                      key={row.id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          {/* <Avatar alt={nome_fantasia} src={avatarUrl} /> */}
                          <Typography variant="subtitle2" noWrap>
                            {row.nome_fantasia}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{row.cpf_cnpj}</TableCell>
                      <TableCell align="left">{row.registrado_em && getLegibleDate(new Date(row.registrado_em))}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={row.status === 'ATIVO' ? 'success' : 'error'}
                        >
                          {sentenceCase(row.status)}
                        </Label>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsperpage}
          rowsPerPage={rowsPerPage}
          page={page}
        />
      </Card>
    </Container>
  );
}

