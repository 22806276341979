import axios from 'axios';
import { executeQuotaErrorCallbacks } from 'workbox-core/_private';


export async function fetchCNPJData(cnpj) {
    try {
        const response = await axios.get(`${window.ENV.API_URL}/receita/cnpj/${cnpj}`);
        return response.data;
    } catch {
        return {};
    }
}