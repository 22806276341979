import { useEffect, useState } from 'react';


import { Box, Grid, Container, Typography, Link } from '@mui/material';

import Page from '../../../components/Page';

import { RemoteServices } from "../../../services"

import Lottie from "lottie-react-web"

import DownloadsAnimate from "../../../assests/animates/downloads.json"

import { Downs } from "./items"

import { Tab, Tabs } from "@mui/material"

import Fv from "./fv"


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}








export default function DashboardApp() {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Page title="Dashboard | Revenda">
            <Container maxWidth="xl">

                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Força de Vendas" {...a11yProps(0)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Fv />
                </TabPanel>
            </Container>

        </Page>
    );
}
