import React, {createContext, useContext, useEffect, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RemoteServices } from "../services";
import { verifyToken } from "../utils/verifyToken";


export const AuthManager = createContext({});
const useAuth = () => useContext(AuthManager);

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [externalLink, setExternalLink] = useState('')
    const [perfil, setPerfil] = useState('')
    const [ user, setUser ] = useState(() => {
        const token = localStorage.getItem('@rttoken');
        const userData = localStorage.getItem('@rtdata');

        if (token && verifyToken(token) && userData) {
            const toJSON = JSON.parse(userData);
            return toJSON;
        }
        return null;
    });

    const logout = () => {
        localStorage.removeItem('@rttoken');
        localStorage.removeItem('@rtdata');
        setUser(null);
    }

    const makeLogin = async (token, navigateAtRoot = true) => {
        
        localStorage.setItem('@rttoken', token);
        const response = await RemoteServices.user.retrive();
        if (response.error) {
            // showSnack(navigateAtRoot ? response.error : 'Sessão expirada', 'error');
            if (!navigateAtRoot) {
                logout();
                await RemoteServices.user.retrive();
                navigate('/login');
            }
            return;
        }
        localStorage.setItem('@rtdata', JSON.stringify(response));
        setUser(response);
        //msg
        console.clear();
        if (navigateAtRoot) {
            externalLink ? console.log('verdadeiro') : console.log('falso')
            const url = new URL(window.location.href);
            externalLink.length > 0 ? 
                window.location.href = url.origin + `/admin/forca-vendas/?cobrancas=${externalLink}`
            : 
            navigate(`dashboard/${user.perfil === 'revenda' ? 'app' : 'admin'}`);
        }
    }

    useEffect(() => {
        if (user) {
            const token = localStorage.getItem('@rttoken');
            if (token) {
                makeLogin(token, false);
            } else {
                setUser(null);
            }
        }
    }, []);

    return (
        <AuthManager.Provider value={{
            makeLogin,
            user,
            logout,
            perfil,
            setPerfil,
            setExternalLink,
            externalLink
        }}>
            {children}
        </AuthManager.Provider>
    )
}

export default useAuth;
