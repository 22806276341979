import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

import AuthLogin from "../pages/Login"


const AuthenticationRoutes = {
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
        {
            path: '/',
            element:  <AuthLogin />
        },
        { 
            path: '*', 
            element: <Navigate to="/" />
        }
    ]
};

export default AuthenticationRoutes;
