import {fetchCNPJData} from './ExternalServices/fetch_cnpj_data';
import { fetchCEPData } from './ExternalServices/fetch_cep_data';

import { registerUser } from './SelfServices/users/register';
import { login } from './SelfServices/users/login';
import { retrive } from './SelfServices/users/retrive';


import { registerClient } from './SelfServices/register/register';
import { registerUserLoja } from './SelfServices/register/registerUserLoja';


import { Payment } from './SelfServices/payments/payment';
import { makerPaid } from './SelfServices/payments/makerPaid';
import { cancelPaid } from './SelfServices/payments/cancelPaid';
import { isencao } from './SelfServices/payments/isencao';
import { ReportFinance } from './SelfServices/payments/ReportFinance';

import { requireUsersList } from './SelfServices/users/listUsers';
import { updateUser } from './SelfServices/users/updateUser';
import { updateMe } from './SelfServices/users/updateMe';
import { requireUsersCobrancas } from './SelfServices/users/cobrancas';
import { requireUsersPagaments } from './SelfServices/users/pagaments';
import { DeleteUser } from './SelfServices/users/deleteUser';
import { UnlockShop } from './SelfServices/users/unlockShop';

import { Loja } from './SelfServices/apiPrev/loja';
import { UpdateSalesman } from './SelfServices/apiPrev/updateSalesman';
import { Teste } from './SelfServices/apiPrev/teste';
import { CreateUser } from './SelfServices/apiPrev/createUser';


export const RemoteServices = {
    fetchCNPJData,
    fetchCEPData,
    user: {
        register: registerUser,
        registerUserLoja,
        login,
        retrive,
        requireUsersList,
        requireUsersCobrancas,
        requireUsersPagaments,
        updateUser,
        updateMe,
        UnlockShop
    },
    resale:{
        registerClient
    },
    payments: {
        Payment,
        makerPaid,
        cancelPaid,
        isencao,
        ReportFinance
    },
    apiPrev: {
        Loja,
        UpdateSalesman,
        Teste,
        CreateUser,
        DeleteUser
    }
}
