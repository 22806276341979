import { useRoutes } from 'react-router-dom';

import ResalesRoutes from './ResalesRoutes';
import AuthenticationRoutes from './loginRoutes';
import AdminRoutes from './AdminRoutes';
import StoreRoutes from './StoreRoutes';
import NotFoundRoute from "./404";

import useAuth from '../context/auth';

export default function ThemeRoutes() {
    const { perfil, user } = useAuth();

    return useRoutes([
        !user ?
        AuthenticationRoutes :
        user.perfil === 'revenda' ? ResalesRoutes :
        user.perfil === 'loja' ? StoreRoutes :
        AdminRoutes,
        NotFoundRoute
    ]);
}
