const { gatewayPrev  } = require("../gateway")

export const Teste = async (registersIDs) => {
    try {
        const response = await gatewayPrev.get(`/clients/users/?exceptions=${registersIDs.join(',')}`);
        return response.data;
    } catch(reason) {
        if (reason.response) {
            const { data } = reason.response;
            data.severity = 'error';

            return data;
        }
        return {
            error: "Problemas ao se conectar com o serviço",
            severity: 'error'
        }
    }
}