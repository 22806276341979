const { default: gateway } = require("../gateway")

export const isencao = async (loja, app) => {
    try {
        const response = await gateway.patch(`/finance/isencao`,
            loja, app
        );
        response.data.statusCode = response.status;
        return response.data;
    } catch(reason) {
        if (reason.response) {
            const { data } = reason.response;
            data.statusCode = reason.status;
            data.severity = 'error';

            return data;
        }
        return {
            error: "Problemas ao se conectar com o serviço",
            severity: 'error'
        }
    }
}