import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    IconButton,
    Grid,
    Tooltip
} from '@mui/material';

import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../components/_dashboard/user';

import USERLIST from '../../../_mocks_/user';

import { RemoteServices } from "../../../services";

import { getLegibleDate, toCashBR } from "../../../utils/functionTools"

import { MdDone, FiX } from "react-icons/all"


const TABLE_HEAD = [
    { id: 'id', label: 'Id', alignRight: false },
    { id: 'gerada_em', label: 'Gerada', alignRight: false },
    { id: 'sitacao', label: 'Situação', alignRight: false },
    { id: 'metodo_pagamento', label: 'Metodo de pagamento', alignRight: false },
    { id: 'valor', label: 'Valor', alignRight: false },
    { id: 'revenda', label: 'Revenda', alignRight: false },
    { id: 'acoes', label: 'Ações', alignRight: false }
];



export default function Payments({ 
    data, 
    count, 
    makerPay, 
    cancelPay, 
    setSearch, 
    search, 
    loading,
    filter,
    setPage,
    page,
    ClickEvent
}) {

    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsperpage = (event, newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage.props.value)
    }



    return (
        <Container>
            <Card>
                <UserListToolbar
                    numSelected={selected.length}
                    filterName={search}
                    loading={loading}
                    filterItems={[
                        'Aguardando'.toLocaleUpperCase(), 
                        'Pago'.toLocaleUpperCase(), 
                        'Nao pago'.toLocaleUpperCase(), 
                        'Cancelado'.toLocaleUpperCase()
                    ]}
                    filterSelected={e => filter(e)}
                    onFilterName={e => setSearch(e.target.value)}
                    noSearch
                />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={count}
                                numSelected={selected.length}
                            />
                            <TableBody>
                                {data.length > 0 && data.map((row) => {
                                    const isItemSelected = selected.indexOf(row.nome_fantasia) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => ClickEvent(row)}
                                            key={row.id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    {/* <Avatar alt={nome_fantasia} src={avatarUrl} /> */}
                                                    <Typography variant="subtitle2" noWrap>
                                                        {row.pagamento_id}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">{row.criado_em && getLegibleDate(new Date(row.criado_em))}</TableCell>
                                            <TableCell align="left">
                                                <Label
                                                    variant="ghost"
                                                    color={row.status === 'PAGO' ? 'success' : row.status === 'AGUARDANDO' ? 'primary' : 'error'}
                                                >
                                                    {sentenceCase(row.status)}
                                                </Label>
                                            </TableCell>
                                            <TableCell align="left">{row.metodo_pagamento}</TableCell>
                                            <TableCell align="left">{toCashBR(row.valor ? row.valor : 0)}</TableCell>
                                            <TableCell align="left">{row.revenda_razao_social}</TableCell>
                                            <TableCell align="right">
                                                <Grid width={80}>
                                                    <Tooltip title="Marcar como pago (ao clicar pagamento sera marcado como pago)">
                                                        <IconButton onClick={() =>
                                                             makerPay(row.pagamento_id)
                                                             }>
                                                            <MdDone color="#16DE59" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Cancelar pagamento (ao clicar pagamento cancelado)">
                                                        <IconButton onClick={() =>
                                                            cancelPay(row.pagamento_id)
                                                            }>
                                                            <FiX color="#DE165F" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsperpage}
                    rowsPerPage={rowsPerPage}
                    page={page}
                />
            </Card>
        </Container>
    );
}

