import { useEffect, useState } from 'react';


import { 
    Box, 
    Grid, 
    Container, 
    Typography, 
    Link 
} from '@mui/material';

import Page from '../../../components/Page';

import InfosClient from "./infosClient"
import NewClient from "./newClient"
import { Tab, Tabs } from "@mui/material"

import ClientsTable from "../../User"



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}




export default function Clients() {

    const [value, setValue] = useState(0);
    const [Infos, setInfos] = useState(false)
    const [newClient, setNewClient] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Page title="Dashboard | Revenda">
            <Container maxWidth="xl">

                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Força de Vendas" {...a11yProps(0)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <ClientsTable setInfos={e => setInfos(e)} setNewClient={setNewClient}/>
                </TabPanel>
            </Container>
        <InfosClient client={Infos} setClient={setInfos}/>
        <NewClient client={newClient} setClient={setNewClient}/>
        </Page>
    );
}
