import React from "react"
import { toast, Slide } from 'react-toastify'
import "./style.css"
const ToastComponent = ({ textMain, subText, type, block, alert }) => {

    const ToastContent = ({ text, role }) => (
        <>
            <div>
                <div >
                    <h4 className={alert && 'toast'} >{text}</h4>
                </div>
            </div>
            <div>
                <h6 >{role}</h6>
            </div>
        </>
    )
    if (type === 'error') {
        const ToastErro = toast.error(
            <ToastContent text={textMain} role={subText} />,
            { icon: false, transition: Slide, hideProgressBar: false, autoClose: block ? false : 4000 }
        )
        return ToastErro
    }
    
    const Toastsuccess = toast.success(
        <ToastContent text={textMain} role={subText} />,
        { icon: false, transition: Slide, hideProgressBar: false, autoClose: 4000 }
    )
    return Toastsuccess
}

export default ToastComponent;