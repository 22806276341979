// component
import Iconify from '../../components/Iconify';


const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Força de Vendas',
    path: '/dashboard/admin',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: 'Downloads',
    path: '/dashboard/downloads',
    icon: getIcon('mdi:arrow-down')
  },
  {
    title: 'Clientes',
    path: '/dashboard/Clientes',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'Pagamantos',
    path: '/dashboard/Payments',
    icon: getIcon('emojione-monotone:money-bag')
  },
];

export default sidebarConfig;
