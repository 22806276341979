import InvoicePage from "../pages/resales/invoice"
import DownloadsPage from "../pages/resales/donwloads"
import ClientsPage from "../pages/resales/clients"
import DashboardLayout from '../layouts/dashboard'
import { Navigate } from 'react-router-dom'

const ResalesRoutes = {
    path: '/',
    element: <DashboardLayout />,
    children: [
        { 
            path: '/', 
            element: <Navigate to="/dashboard/app" />
        },
        {
            path: '/dashboard/app',
            element: <InvoicePage />
        },
        {
            path: '/dashboard/downloads',
            element: <DownloadsPage />
        },
        {
            path: '/dashboard/Clientes',
            element: <ClientsPage />
        },
    ]};

export default ResalesRoutes;
