import React, { useEffect, useState } from "react"

import { Grid, Container } from "@mui/material"


import Lottie from "lottie-react-web"
import Analytics from "../../assests/animates/analytics.json"


import { RemoteServices } from "../../services"

import MoneyTickets from "./MoneyTickets"
import Isent from "./isent"
import Unlock from "./unlock"

const Money = () => {


    return (
            <Grid xs={12} justifyContent="center" container>
                <Grid item>
                    <Lottie
                        options={{
                            animationData: Analytics
                        }}
                        width={500}
                    />

                </Grid>
                <Grid item width={720}>
                    <MoneyTickets />
                    <Isent />
                    <Unlock />
                </Grid>
            </Grid>
    )
}

export default Money;