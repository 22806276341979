import React, { useEffect, useState } from "react"

import { Grid, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material"

import { months, toCashBR } from "../../../utils/functionTools"

import { RemoteServices } from "../../../services"

const Money = () => {


    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [total, setTotal] = useState(0)


    const handleChange = (event, e) => {
        e && e === 'year' ? setYear(event.target.value) : setMonth(event.target.value)
    };



    const reportFinance = async () => {
        !year && setMonth(null)
        await RemoteServices.payments.ReportFinance({
            ano: year && year !== 'Todos' ? year : null,
            mes: year && year !== 'Todos' && month && month !== 'Todos' ? month : null,
            app: 1
        }).then((response) => {
            setTotal(response.total)
        }).catch(() => {
            showSnack('não foi possível conectar-se ao serviço', 'error')
        })
    }


    useEffect(() => {
        reportFinance()
    }, [year, month])


    return (
        <Grid xs={12} container justifyContent={"center"}>
            <Grid xs={8} bgcolor="#E5E5E5" p={2} borderRadius={2} spacing={2} container>
                <Grid xs={6} item>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Ano</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={year}
                            label="Ano"
                            onChange={e => handleChange(e, 'year')}
                        >
                            {
                                ['Todos', '2021', '2022'].map((e, i) => (
                                    <MenuItem key={`${e}-${i}`} value={e} style={{ color: '#000' }}>{e}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={6} item>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Mês</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={month}
                            label="Mês"
                            onChange={handleChange}
                        >
                            <MenuItem value={'Todos'} style={{ color: '#000' }}>Todos</MenuItem>
                            {months.map((e, i) => (
                                <MenuItem key={`${e}-${i}`} value={i + 1} style={{ color: '#000' }}>{e}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container >
                    <Typography sx={{ ml: 2 }} variant="h3">{toCashBR(total ? total : 0)}</Typography>
                    <Typography sx={{ ml: 2, fontSize: 12 }}>ESSE VALOR SE REFERE A COBRANÇAS EFETIVAMENTE PAGAS NO PERÍODO</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Money;