import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination
} from '@mui/material';

import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../components/_dashboard/user';

import USERLIST from '../../../_mocks_/user';

import { RemoteServices } from "../../../services";

import { getLegibleDate } from "../../../utils/functionTools"




export default function Resales({ setInfos, setNewClient, revenda, loja }) {


    const TABLE_LOJA = [
        { id: 'registrado_em', label: 'Registro', alignRight: false },
        { id: 'status', label: 'Status', alignRight: false }
    ]

    const TABLE_HEAD = [
        { id: 'name', label: 'Fantasia', alignRight: false },
        { id: 'razao', label: 'Razão Social', alignRight: false },
        { id: 'company', label: 'Cpf/Cnpj', alignRight: false },
        { id: 'email', label: 'E-mail', alignRight: false },
        { id: 'uf', label: 'Uf', alignRight: false },
        { id: 'cidade', label: 'Cidade', alignRight: false },
        { id: 'telefone', label: 'Telefone', alignRight: false },

    ];




    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState('')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsperpage = (event, newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage.props.value)
    }

    const [count, setCount] = useState(0)
    const [data, setData] = useState([])


    useEffect(() => {
        setLoading(true)
        RemoteServices.user.requireUsersList({
            app: 1,
            limit: rowsPerPage,
            page: page + 1,
            fragment: filterName,
            ...(revenda && { revenda: revenda }),
            ...(loja && { perfil: 'loja' })
        })
            .then(response => {
                if (response.error) {
                    return
                }
                if (response.usuarios.length > 0) {
                    setData(response.usuarios)
                }
                setCount(response.contagem)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [page, rowsPerPage, filterName])


    return (
        <>
            {
                !loja && (
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Clientes força de vendas
                        </Typography>
                    </Stack>
                )
            }
            <Card
                sx={{
                    ...(loja && { maxHeight: '75vh', overflowY: 'scroll' })
                }}
            >
                <UserListToolbar
                    numSelected={0}
                    filterName={filterName}
                    loading={loading}
                    onFilterName={e => setFilterName(e.target.value)}
                />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={!loja ? TABLE_HEAD : [...TABLE_HEAD, ...TABLE_LOJA]}
                                rowCount={count}
                                numSelected={0}
                            />
                            <TableBody>
                                {data && data.length > 0 && data.map((row) => {
                                    const isItemSelected = selected.indexOf(row.nome_fantasia) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            sx={{
                                                ":hover": {
                                                    cursor: 'pointer'
                                                }
                                            }}
                                            onClick={() => setInfos(row)}
                                            key={row.id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    {/* <Avatar alt={nome_fantasia} src={avatarUrl} /> */}
                                                    <Typography variant="subtitle2" noWrap>
                                                        {row.nome_fantasia && row.nome_fantasia}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">{row.razao_social}</TableCell>
                                            <TableCell align="left">{row.cpf_cnpj}</TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="left">{row.uf}</TableCell>
                                            <TableCell align="left">{row.cidade}</TableCell>
                                            <TableCell align="left">{row.telefone}</TableCell>
                                            {
                                                loja && (
                                                    <>
                                                        <TableCell align="left">{row.registrado_em}</TableCell>
                                                        <Label
                                                            variant="ghost"
                                                            color={row.status === 'ATIVO' ? 'success' : 'error'}
                                                        >
                                                            {sentenceCase(row.status)}
                                                        </Label>
                                                        {/* <TableCell align="left" color={row.status === 'ATIVO' ? 'success' : 'error'}>{row.status}</TableCell> */}
                                                    </>

                                                )
                                            }
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsperpage}
                    rowsPerPage={rowsPerPage}
                    page={page}
                />
            </Card>
        </>
    );
}

