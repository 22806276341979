import React, { useState, useEffect, useRef } from 'react';

import {
    Box,
    Button,
    Typography,
    Modal,
    Divider,
    Grid,
    List,
    Tab,
    Tabs,
    SwipeableDrawer,
    ListItem,
    ListItemIcon,
    ListItemText,
    Slide,
    Paper,
} from "@mui/material"
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import Loja from "./resales"

import Table from "../../resales/invoice"

import { BiArrowBack } from "react-icons/all"

import { RemoteServices } from "../../../services"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '65vw',
    maxHeigth: '75vh',
    borderRadius: 2,

};



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}




export default function ResalesClients({ client, setClient }) {
    const handleClose = () => { setClient(false); setData({}); setChecked(false) };


    const [value, setValue] = useState(0);
    const [Infos, setInfos] = useState(false)
    const [newClient, setNewClient] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)
    const [filter, setFilter] = useState('')

    const [data, setData] = useState([])
    const [count, setCount] = useState(0)

    const [openInfos, setOpenInfos] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setData([]);
    };

    const [checked, setChecked] = useState(false);
    const [rev, setev] = useState(false);

    const containerRef = useRef(null);


    const getCobrancas = () => {
        // setLoading(true)
        setData([])
        RemoteServices.user.requireUsersCobrancas({
            page: pageIndex + 1, limit: 5,  status: filter === 'TODAS' ? null : filter ,...(checked && { loja: checked.id }), ...(!checked && { revenda: client.id }), 
        }).then(r => {
            if (r.error) {
                return
            }
            setCount(r.contagem)
            setData(r.cobrancas)
        }).catch((err) => {
        }).finally(() => {
            // setLoading(false);
        })
    }




    const icon = (

        <Grid xs={12}>
            {
                checked &&
                <>
                    <Button
                        onClick={() => { setChecked(false); setData([]) }}
                    >
                        <BiArrowBack size={20} />
                    </Button>
                    <Table
                        adminCobran={data}
                        count={count}
                        onlyTable
                        setCount={setCount}
                        setPageIndex={setPageIndex}
                        pageIndex={pageIndex}
                    />
                </>
            }

        </Grid>
    );


    useEffect(() => {
        getCobrancas()
        console.log(filter)
    }, [checked, value, pageIndex, filter])


    return (
        <Modal
            open={client}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box
                component="form"
                sx={{
                    '&.MuiTextField-root': { m: 2, width: '32ch' },


                }}
                noValidate
                autoComplete="off"

                sx={style}>
                <Typography
                    id="modal-modal-title"
                    variant="h3"
                    component="h1"
                    sx={{ color: '#637381' }}
                >
                    {client && client.razao_social}
                </Typography>
                <Slide
                    direction="left"
                    in={!checked}
                    container={containerRef.current}
                >
                    <Grid
                        xs={12}
                    >

                        {
                            !checked && <>
                                <Tabs value={value} onChange={handleChange}>
                                    <Tab label="Clientes" {...a11yProps(0)} />
                                    <Tab label="Faturas" {...a11yProps(1)} />
                                </Tabs>
                                <TabPanel value={value} index={0}>
                                    <Loja loja revenda={client && client.id} setInfos={e => setChecked(e)} />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Grid xs={12} maxHeight={'75vh'} overflow="scroll">

                                        <Table
                                            filterOut={filter}
                                            setFilterOut={setFilter}
                                            adminCobran={data}
                                            count={count}
                                            setCount={setCount}
                                            onlyTable
                                            pageIndexOut={pageIndex}
                                            setPageIndexOut={setPageIndex} />
                                    </Grid>
                                </TabPanel>
                            </>
                        }
                    </Grid>
                </Slide>

                <Grid xs={12}>
                    <Slide
                        direction="left"
                        in={checked}
                        container={containerRef.current}
                    >
                        {icon}
                    </Slide>
                </Grid>
            </Box>
        </Modal>
    );
}