import { FaServer, FaMobileAlt, FaFileAlt, FaFileContract } from "react-icons/all"


export const Downs = [
    { 
        name: 'Instalador do serviço', 
        icon: <FaServer color="#6A2C9B" size={80} />, 
        link: 'https://rottamobile.com.br/static/fvinterna/forcadevenda-instalador.zip' 
    },
    { 
        name: 'Aplicativo do Android', 
        icon: <FaMobileAlt color="#6A2C9B" size={80} /> ,
        link: 'https://play.google.com/store/apps/details?id=com.hotline.forcadevendas' 

    },
    { 
        name: 'Passo a passo instalação', 
        icon: <FaFileAlt color="#6A2C9B" size={80} />,
        link: 'https://rottamobile.com.br/static/fvinterna/passo-a-passo.pdf' 

    },
    { 
        name: 'Guia inlustrado de funcionalidades', 
        icon: <FaFileContract color="#6A2C9B" size={80} />,
        link: 'https://rottamobile.com.br/static/fvinterna/guia-app-forca-de-venda.pdf' 

    },
    // { name: 'Rota pagamentos', icon: <FaServer color="#6A2C9B" size={80}/> },
    // { name: 'Consulta preço', icon: <FaServer color="#6A2C9B" size={80}/> },

]