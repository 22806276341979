import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

import { alpha, styled } from '@mui/material/styles';

import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { RemoteServices } from "../../../services"
import Iconify from '../../Iconify';

import { toast, Slide } from 'react-toastify'

import useAuth from "../../../context/auth"

export default function LoginForm() {

  const { makeLogin } = useAuth()

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('endereço de e-mail inválido').required('Informe seu endereço de e-mail'),
    password: Yup.string().required('Informe sua senha')
  });


  const ToastContent = ({ text, role }) => (
    <>
        <div>
            <div >
                <h4>{text}</h4>
            </div>
        </div>
        <div>
            <h6 >{role}</h6>
        </div>
    </>
)


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: values => {
      setLoading(true)
      RemoteServices.user.login(
        values.email ,
        values.password,
        'admin'
      )
        .then(response => {
          if (response.error) {
            toast.error(
              <ToastContent text={response.error} role={'contate o suporte caso o problema persista!'} />,
              { icon: false, transition: Slide, hideProgressBar: false, autoClose: 4000 }
          )
            return;
          }
          makeLogin(response.token);
        }
        )
        .finally(() => setLoading(false))
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };




  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <CssTextField
            fullWidth
            autoComplete="username"
            type="email"
            color='primary'
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            
          />

          <CssTextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            InputProps={{
                
            }}
            {...getFieldProps('password')}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>

        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}


const CssTextField = styled(TextField)({
  backgroundColor: '#141418',
  borderRadius: 1,
  color: '#fff',
  '& label.Mui-focused': {
    color: 'primary',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'primary',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'primary',
    },
    '&:hover fieldset': {
      borderColor: 'primary',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'primary',
    },
  },
});