import StorePage from "../pages/loja/index"
import DashboardLayout from '../layouts/dashboard'
import { Navigate } from 'react-router-dom'

const ResalesRoutes = {
    path: '/',
    element: <DashboardLayout />,
    children: [
        { 
            path: '/', 
            element: <Navigate to="/dashboard/app" />
        },
        {
            path: '/dashboard/app',
            element: <StorePage />
        },
    ]};

export default ResalesRoutes;
