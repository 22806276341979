// ----------------------------------------------------------------------

const account = (name) => {
  return {
    displayName: name,
    email: 'demo@minimals.cc',
    photoURL: '/static/mock-images/avatars/avatar_default.jpg'
  }
};

export default account;
