import React, { useEffect, useState } from "react"
import {
    Modal,
    Box,
    TextField,
    Typography,
    Button
} from "@mui/material"

import { RemoteServices } from "../../services"

import Toast from "../../components/toast"

const ChangePass = ({ _id, close }) => {


    const [prevPass, setPrevPass] = useState('')
    const [pass, setPass] = useState('')

    const [loading, setLoading] = useState(false)

    const updateMe = async () => {

        if (prevPass.length > 12) {
            return Toast({
                textMain: 'senha deve ter no maxímo 12 caracteres!',
                subText: 'senha deve ter no minimo 3 caracteres, e no maxímo 12 caracteres!',
                type: 'error'
            })
        }


        if (prevPass.length < 4) {
            return Toast({
                textMain: 'senha deve ter no minimo 3 caracteres!',
                subText: 'senha deve ter no minimo 3 caracteres, e no maxímo 12 caracteres!',
                type: 'error'
            })
        }

        if (prevPass === '' || pass === '') {
            return Toast({
                textMain: 'Preencha todos os compos!',
                subText: 'para prosseguir você deve preencher todos os campos',
                type: 'error'
            })
        }

        if (prevPass !== pass) {
            return Toast({
                textMain: 'As senhas não se coincidem',
                subText: 'as senhas devem ser iguais',
                type: 'error'
            })
        }
        setLoading(true)
        await RemoteServices.user.updateMe({
            senha: pass
        })
            .then((e) => {
                if (e.error) {
                    return Toast({
                        textMain: 'Não foi possivel alterar a senha!',
                        subText: 'contate o suporte caso o problema persista!',
                        type: 'error'
                    })
                }
                close(false)
                return Toast({
                    textMain: 'Senha alterada',
                    subText: 'sua senha foi alterada com successo!'
                })
                
            })
            .catch((err) => {
                showSnack(err, 'error')
            }).finally(() => setLoading(false))
    }



    return (
        <Modal
            onClose={() => close(false)}
            open={_id}>
            <Box sx={style}>
                <Typography>Alterar senha</Typography>
                <TextField
                    sx={{ mt: 2 }}
                    value={prevPass}
                    type={'password'}
                    onChange={e => setPrevPass(e.target.value)}
                    fullWidth label="Nova senha" />
                <TextField
                    sx={{ mt: 2 }}
                    value={pass}
                    type={'password'}
                    onChange={e => setPass(e.target.value)}
                    fullWidth label="Repita a senha" />
                <Button
                    fullWidth
                    variant="contained"
                    disabled={loading}
                    onClick={updateMe}
                    sx={{ mt: 2 }}>Alterar senha</Button>
                    <Button
                    fullWidth
                    color="error"
                    disabled={loading}
                    onClick={() => close(false)}
                    sx={{ mt: 2 }}>Cancelar</Button>
            </Box>
        </Modal>
    )
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    display: 'flex',
    minWidth: 200,
    flexDirection: 'column',
    border: 'none',
    boxShadow: 24,
    maxHeight: '75vh',
    minWidth: 350,
    p: 4,
};

export default ChangePass