import React, { useEffect, useState } from "react"

import { Grid, TextField, Button, Slide, Snackbar, Typography } from "@mui/material"

import { months, toCashBR } from "../../../utils/functionTools"

import { RemoteServices } from "../../../services"

const Unlock = () => {

    const [unlock, setUnlock] = useState('')
    const [loading, setLoading] = useState(false)
    const [loja, setLoja] = useState({ contagem: 0, lojas: [], days: 0 })


    const getLoja = (fragment, app) => {

        RemoteServices.user.requireUsersList({
            page: 1, limit: 1, perfil: 'loja', fragment: fragment, app: 1
        })
            .then((e) => {
                setLoja({ contagem: e.contagem, lojas: e.usuarios[0] })
            })
            .catch((err) => { })
            .finally(() => setLoading(false))
    }



    const unlockStore = async (id, app, dias) => {
        setLoading(true)

        await RemoteServices.user.UnlockShop(
            {
                app: app,
                loja: id,
                dias: dias
            })
            .then((e) => {
                if (e) {
                    if (e.error) {
                        return
                    }
                    getLoja(unlock)
                }
            })
            .catch((err) => {
                showSnack(err.error, err.severity)
            })
            .finally(() => {
                setLoading(false)
            })

    }



    useEffect(() => {
        if (unlock.length > 10)
            getLoja(unlock)
        if (unlock.length < 10) {
            setLoja({ contagem: 0, lojas: { isento: false } })
        }
    }, [unlock])



    return (
        <Grid mt={5} container justifyContent={"center"} xs={12}>
            <Grid xs={8} bgcolor="#E5E5E5" p={2} borderRadius={2} spacing={2} container>
                <Grid container xs={12} spacing={1}>
                    <Grid xs={6} item>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Cpf/Cnpj"
                            type={'number'}
                            onChange={e => setUnlock(e.target.value)}
                            variant="outlined" />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            fullWidth
                            onChange={e => { setLoja({ ...loja, days: parseInt(e.target.value) }) }}
                            id="outlined-basic"
                            label="Dias Desbloqueado"
                            type={'number'}
                            variant="outlined" />
                    </Grid>
                </Grid>
                <Button
                    disabled={unlock.length < 10 || loading || loja.lojas && loja.lojas.status === 'ATIVO' || loja.days < 1}
                    fullWidth
                    onClick={() => {
                        unlockStore(loja.lojas.id, 1, loja.days)
                    }}
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="error">
                    Desbloquear
                </Button>
            </Grid>
            <Snackbar
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                open={open && unlock.length > 10}
                TransitionComponent={Slide}
                key={'up'}
            >
                <Grid
                    minWidth={300}
                    bgcolor="#CFCFCF"
                    borderRadius={2}
                    p={2}
                >
                    {
                        loja.contagem !== 0 ?
                            <>
                                <Grid
                                    container
                                    sx={12}
                                    justifyContent="space-between">
                                    <Typography
                                        component="span"
                                    >Razão Social: </Typography>
                                    <Typography
                                        color="#8626D1"

                                        component="span"
                                        sx={{ ml: 1 }}>{loja && loja.lojas.razao_social}</Typography>
                                </Grid>
                                <Grid
                                    container
                                    sx={12}
                                    justifyContent="space-between">
                                    <Typography
                                        component="span"
                                    >Fantasia: </Typography>
                                    <Typography
                                        component="span"
                                        color="#8626D1"

                                        sx={{ ml: 1 }}>{loja && loja.lojas.nome_fantasia}</Typography>
                                </Grid>
                                <Grid
                                    container
                                    sx={12}
                                    justifyContent="space-between">
                                    <Typography
                                        component="span"
                                    >E-mail: </Typography>
                                    <Typography
                                        component="span"
                                        color="#8626D1"
                                        sx={{ ml: 1 }}>{loja && loja.lojas.email}</Typography>
                                </Grid>
                                <Grid
                                    container
                                    sx={12}
                                    justifyContent="space-between">
                                    <Typography
                                        component="span"
                                    >Cpf/Cnpj: </Typography>
                                    <Typography
                                        component="span"
                                        color="#8626D1"
                                        sx={{ ml: 1 }}>{loja && loja.lojas.cpf_cnpj}</Typography>
                                </Grid>
                                <Grid
                                    container
                                    sx={12}
                                    justifyContent="space-between">
                                    <Typography
                                        component="span"
                                    >Isenta: </Typography>
                                    <Typography
                                        color="#8626D1"
                                        component="span"
                                        sx={{ ml: 1 }}>{loja && !loja.lojas.isento && 'Não'} Isento</Typography>
                                </Grid>
                            </>
                            :
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: "center"
                                }}
                            >Loja não Encontrada</Typography>
                    }
                </Grid>
            </Snackbar>
        </Grid>
    )
}

export default Unlock;