import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from "@mui/material";

import Lottie from "lottie-react-web"
import PayCancelAnimate from "../../assests/animates/filled.json"
import PaySuccessAnimate from "../../assests/animates/success.json"

import { getLegibleDate, toCashBR } from "../../utils/functionTools"
import { FaBarcode, FaCreditCard, BiX } from "react-icons/all";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '50vw',
    minHeight: 200,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

export default function MethodPayment({
    invoice,
    pix,
    setOpen,
    setPix,
    setCartao,
    cartao,
    setBoleto,
    boleto
}) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [visible, setVisble] = useState(true)

    useEffect(() => {
    }, [invoice])

    return (
        <Modal
            open={invoice}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {
                    invoice &&
                    <>
                        <Grid
                            xs={12}
                            container
                            justifyContent={"flex-end"}>
                            <Button
                                onClick={handleClose}
                                color="error"
                                variant="contained"
                                sx={{
                                    marginRight: -7,
                                    marginTop: -7,
                                    borderRadius: 1,
                                    width: 2,
                                    height: 40,
                                    padding: 0
                                }}><BiX size={20} color="white" /></Button>
                        </Grid>
                        <Typography
                            id="modal-modal-title"
                            variant="h4"
                            component="h3">
                            PAGAMENTO
                            {invoice && invoice.status &&
                                invoice.status === 'CANCELADA' ? ' CANCELADO' : invoice.status === ' NAO PAGA' ? ' NÃO PAGO' : ' CONFIRMADO'}
                        </Typography>
                        <Grid container xs={12} mt={2}>
                            <Grid
                                container
                                xs={4}
                                p={1}
                                bgcolor={invoice && invoice.status &&
                                    invoice.status === 'CANCELADA' || invoice.status === 'NAO PAGA' ? "#D0053930" : "#6807B330"}
                                borderRadius={2}>
                                <Lottie
                                    options={{
                                        animationData: invoice && invoice.status &&
                                            invoice.status === 'CANCELADA' ||
                                            invoice.status === 'NAO PAGA' ? PayCancelAnimate : PaySuccessAnimate,
                                        loop: false
                                    }}
                                    width={180}
                                />
                            </Grid>
                            <Grid container xs={7} ml={2}>
                                <Grid container xs={12}>
                                    <Grid container xs={12} justifyContent="space-between">
                                        <Grid>
                                            <span>Razão social: {' '}</span>
                                            <Typography
                                                variant='h6'
                                                component={'span'}>{invoice && invoice.loja_fantasia}
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                        <span>Valor: {' '}</span>
                                        <Typography
                                            variant='h6'
                                            component={'span'}>{invoice && toCashBR(invoice.valor ? invoice.valor : 0)}
                                        </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12}>
                                    <span>Razão social: {' '}</span>
                                    <Typography
                                        variant='h6'
                                        component={'span'}>{invoice && invoice.loja_razao_social}
                                    </Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <span>Cpf: </span>
                                    <Typography
                                        variant='h6'
                                        component={'span'}>{invoice && invoice.loja_cpf_cnpj}
                                    </Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <span>Cadastro: </span>
                                    <Typography
                                        variant='h6'
                                        component={'span'}>Criada em {invoice && getLegibleDate(new Date(invoice.criada_em))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }

            </Box>
        </Modal>
    );
}