import React, { useEffect, useState } from "react"

import {
    Grid,
    TextField,
    Button,
    Slide,
    Snackbar,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@mui/material"

import { BiMobile } from "react-icons/all"

import { toCashBR, getLegibleDate } from "../../../utils/functionTools"

const SnackbarInfos = ({ pay, setPay }) => {


    const handleClose = () => {
        setPay(false);
    };

    useEffect(() => {
        if (pay) {
            console.log(pay)
        }
    }, [pay])

    return (

        <Snackbar
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            open={pay}
            onClose={handleClose}
            TransitionComponent={Slide}
            key={'up'}
        >
            <Grid
                bgcolor="#CFCFCF"
                borderRadius={2}
                p={2}
            >
                <Grid container xs={12} overflow="-moz-hidden-unscrollable">
                    <Grid container>

                    </Grid>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell >Nome Fantasia</TableCell>
                                <TableCell >Razão Social</TableCell>
                                <TableCell >Vencimento</TableCell>
                                <TableCell >valor</TableCell>
                                <TableCell >App</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                pay && pay.cobrancas.map(c => (
                                    <TableRow>
                                        <TableCell>{c.id}</TableCell>
                                        <TableCell>{c.nome_fantasia}</TableCell>
                                        <TableCell >{c.razao_social}</TableCell>
                                        <TableCell >{getLegibleDate( new Date(c.vence_em))}</TableCell>
                                        <TableCell >{toCashBR(c.valor ? c.valor : 0)}</TableCell>
                                        <TableCell>
                                            <Typography sx={{
                                                p: 1,
                                                backgroundColor: '#6918A830',
                                                color: '#6918A8',
                                                borderRadius: 1
                                            }}>{c.app === 1 ? 'Força de venda': ''}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Snackbar>
    )
}

export default SnackbarInfos;