import React, { useEffect, useState } from 'react';

import { CircularProgress, Modal, Box } from "@mui/material"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

const Loading = ({
    open,
    setOpen,
}) => {

    const handleClose = () => setOpen(false);
    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <CircularProgress />
            </Box>
        </Modal>
    );
}

export default Loading;