import React, { useState, useEffect } from 'react';

import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    Grid,
    CircularProgress
} from "@mui/material"

import { checkEmail, TestaCPF, validarCNPJ } from "../../../utils/functionTools"
import { RemoteServices } from "../../../services"

import { toast, Slide } from 'react-toastify'

import Lottie from "lottie-react-web"

import FormAnimate from "../../../assests/animates/form.json"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '55vw',
    borderRadius: 2,

};

export default function NewClient({ client, setClient }) {
    const handleClose = () => { setClient(false); setData({}) };


    const [data, setData] = useState({
        cpf_cnpj: '',
        loja: {
            email: '',
            razao_social: '',
            nome_fantasia: '',
            senha: "123456"
        }
    })
    const [loading, setLoading] = useState(false)


    const ToastContent = ({ text, role }) => (
        <>
            <div>
                <div >
                    <h4>{text}</h4>
                </div>
            </div>
            <div>
                <h6 >{role}</h6>
            </div>
        </>
    )


    const register = () => {
        var doc = data.cpf_cnpj.replace(/[^0-9]/g, '');
        setLoading(true)
        RemoteServices.resale.registerClient(data, 1).then((a) => {
            if (a.error) {
                toast.error(
                    <ToastContent
                        // text={'Não foi possivel atualizar informações'}
                        text={a.error}
                        role={'Contate o suporte caso o problema persista!'}
                    />,
                    { icon: false, transition: Slide, hideProgressBar: false, autoClose: 4000 }
                )
                return
            }
            toast.success(
                <ToastContent
                    text={'informações atualizadas'}
                    role={'informações do cliente foram atualizadas com sucesso'}
                />,
                { icon: false, transition: Slide, hideProgressBar: false, autoClose: 4000 }
            )
            setClient(null)
        }).catch((err) => {
        }).finally(() => {
            setLoading(false)
        })
    }



    const Check = () => {

        var doc = data.cpf_cnpj.replace(/[^0-9]/g, '');
        const checkDocument = doc.length < 12 ? TestaCPF(doc) : validarCNPJ(doc)
        const checkRazao = data.loja.razao_social.length < 3 ? false : true

        if (!checkDocument || checkEmail(data.loja.email).error || !checkRazao) {
            toast.error(
                <ToastContent
                    text={'Verifique as informções'}
                    role={
                        !TestaCPF(doc) ? 'Cpf invalido' :
                        checkEmail(data.loja.email).error ? 'Email invalido' :
                        !checkRazao ? 'Razão social deve ter no minimo 2 caracteres' :
                        !validarCNPJ(doc) ? 'Cnpj invalido' : 'se o problema persistir consulte o suporte!'
                    }
                />,
                { icon: false, transition: Slide, hideProgressBar: false, autoClose: 4000 }
            )
            return
        }
        register()
    }



    return (
        <Modal
            open={client}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                component="form"
                noValidate
                autoComplete="off"

                sx={style}>
                <Typography
                    id="modal-modal-title"
                    variant="h3"
                    component="h1">
                    Cadastrar novo cliente
                </Typography>

                <Grid container mt={2} xs={12} spacing={2}>
                    <Grid mt={2} container p={2} direction="row" spacing={2} xs={12}>
                        <Grid item borderRadius={2} bgcolor="#ECECEC">
                            <Lottie
                                options={{
                                    animationData: FormAnimate,
                                    loop: false
                                }}
                                width={300}
                            />
                        </Grid>
                        <Grid item >
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Cpf/Cnpj"
                                variant="outlined"
                                onChange={e => setData({ ...data, cpf_cnpj: e.target.value })}
                            />
                            <TextField
                                sx={{ mt: 3 }}
                                fullWidth
                                id="outlined-basic"
                                label="Razão social"
                                variant="outlined"
                                onChange={e => setData({ ...data, loja: { ...data.loja, razao_social: e.target.value } })}
                            />
                            <TextField
                                sx={{ mt: 3 }}
                                fullWidth
                                id="outlined-basic"
                                label="Fantasia"
                                variant="outlined"
                                onChange={e => setData({ ...data, loja: { ...data.loja, nome_fantasia: e.target.value } })}

                            />
                            <TextField
                                sx={{ mt: 3 }}
                                fullWidth
                                id="outlined-basic"
                                label="e-mail"
                                variant="outlined"
                                onChange={e => setData({ ...data, loja: { ...data.loja, email: e.target.value } })}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        mt={2}
                        container
                        justifyContent={"space-between"}
                        direction="row"
                        xs={12}>
                        <Button
                            onClick={handleClose}
                            color="error">Cancelar</Button>
                        <Button
                            style={{ width: 150 }}
                            variant="contained"
                            disabled={loading}
                            onClick={Check}>
                            {
                                loading ? <CircularProgress size={15} /> : "Cadastrar"
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}