import { useEffect, useState } from 'react';


import { Box, Grid, Container, Typography, Link } from '@mui/material';

import Lottie from "lottie-react-web"

import DownloadsAnimate from "../../../assests/animates/downloads.json"

import { Downs } from "./items"

export default function Fv() {
    return (
        <>
            <Box sx={{ pb: 5 }}>
                <Typography variant="h4">Aplicativo Força de Venda</Typography>
            </Box>
            <Grid
                mt={2}
                container
                xs={12}
                justifyContent="space-around"
            >
                <Grid>
                    <Lottie
                        options={{
                            animationData: DownloadsAnimate,
                            loop: false
                        }}
                        width={400}
                    />
                </Grid>
                <Grid maxWidth={550} direction="row" container>

                    {
                        Downs.map((e) => (
                            <Link
                                href={e.link}
                                color='inherit'
                                underline="none"
                                target='_blank'
                                rel="noreferrer"

                            >
                                <Grid
                                    container
                                    justifyContent={'center'}
                                    alignItems="center"
                                    direction={"column"}
                                    height={250}
                                    width={250}
                                    ml={2}
                                    mt={2}
                                    borderRadius={2}
                                    bgcolor="#E2E2E2"
                                    sx={{
                                        transition: '0.5s ease-out',
                                        ":hover": {
                                            transform: 'scale(1.1)',
                                            cursor: 'pointer',
                                            boxShadow: '#6A2C9B35 0px 5px 15px;'
                                        }
                                    }}
                                >
                                    {e.icon}
                                    <Typography variant='h5' textAlign={'center'} >{e.name}</Typography>
                                </Grid>
                            </Link>
                        ))
                    }
                </Grid>
            </Grid>
        </>
    );
}
