import { useEffect, useState } from 'react';


import { Box, Grid, Container, Typography } from '@mui/material';

import Page from '../../components/Page';

import Table from "../../components/Table"

import { RemoteServices } from "../../services"

import Pix from "./methodPayment/pix"
import Cartao from "./methodPayment/cartaoCredito"
import Boleto from "./methodPayment/boleto"
import InfosInvoice from "./infosInvoice"

import MethodPayment from "./methodPayment"

import LoadingPage from "../../components/_dashboard/loading"

import useAuth from "../../context/auth"

export default function DashboardApp({
    onlyTable = false,
    adminCobran,
    count,
    setCount,
    pageIndexOut,
    setPageIndexOut,
    filterOut,
    setFilterOut
}) {

    const { user } = useAuth()

    const [data, setData] = useState([])
    const [pageIndex, setPageIndex] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [filter, setFilter] = useState('')

    const [invoice, setInvoice] = useState(null)
    const [pix, setPix] = useState(null)
    const [cartao, setCartao] = useState(null)
    const [boleto, setBoleto] = useState(null)

    const [invoiceVisble, setInvoiceVisable] = useState(true)
    const [loading, setLoading] = useState(false)

    const [infos, setInfos] = useState(null)

    const [loadingPay, setLoadingPay] = useState(false)

    const [link, setLink] = useState(null)
    const [copy, setCopy] = useState(null)


    const getCobrancas = () => {
        setLoading(true)
        RemoteServices.user.requireUsersCobrancas({
            page: pageIndex ? pageIndex + 1 : 1, limit: 5, status: filter === 'TODAS' ? null : filter
        }).then(r => {
            if (r.error) {
                return
            }
            setData(r.cobrancas)
            setPageCount(r.contagem)

        }).catch((err) => {
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if (setFilterOut) {
            setFilterOut(filter)
        }
    }, [filter])

    useEffect(() => {
        if (!adminCobran) {
            getCobrancas()
        }
        console.log(filter)
    }, [pageIndex, filter])


    const CloseAll = () => {
        setInvoice(null)
        setPix(null)
        setCartao(null)
        setInvoiceVisable(false)
        setBoleto(null)
        setLink(null)
        setCopy(null)
    }


    const getPays = (i) => {
        setLoadingPay(true)
        RemoteServices.user.requireUsersPagaments({
            id: i
        })
            .then(r => {
                const res = r.pagamentos[0]
                if (res.metodo_pagamento === 'pix') {
                    setPix(res)
                    setLink(res.uri)
                    setCopy(res.payload)
                }
                if (res.metodo_pagamento === 'boleto') {
                    setBoleto(res)
                    setLink(res.uri)
                    setCopy(res.payload)
                }
                if (res.metodo_pagamento === 'credit_card') {
                    setCartao(res)
                    setLink(res.uri)
                }

            })
            .catch()
            .finally(() => {
                setLoadingPay(false)

            })
    }

    const CreatePayment = (method, invoice, payer) => {
        setLoadingPay(true)
        RemoteServices.payments.Payment({
            metodo_pagamento: method,
            ...(user.perfil === 'admin' && { usuario: invoice[0].revenda_id }),
            cobrancas: [invoice.map((e) => { return { id: e.cobranca_id } })][0],
            ...(payer && payer)
        })
            .then((e) => {

                if (e.error) {
                    return
                }
                if (method === 'pix') {
                    setLink(e.qrcode_uri)
                    setCopy(e.payload)
                }
                if (method === 'boleto') {
                    setLink(e.boleto_uri)
                    setCopy(e.barcode)
                }
                if (method === 'credit_card') {
                    setLink(e.checkout_uri)
                }
            }).catch((err) => {

            })
            .finally(() => setLoadingPay(false))
    }


    function TableConteiner() {
        return (
            <>
                <Table
                    data={adminCobran ? adminCobran : data}
                    count={adminCobran ? count : pageCount}
                    setPage={e => { !adminCobran ? setPageIndex(e) : setPageIndexOut(e) }}
                    page={!adminCobran ? pageIndex : pageIndexOut}
                    setInvoice={e => setInvoice(e)}
                    setInfos={e => setInfos(e)}
                    pay={getPays}
                    loadingPay={loadingPay}
                    setFilter={setFilter}
                    filter={filter}
                />
                <MethodPayment
                    setPix={setPix}
                    pix={pix}
                    cartao={cartao}
                    setCartao={setCartao}
                    invoice={invoice}
                    setOpen={setInvoice}
                    boleto={boleto}
                    setBoleto={setBoleto}
                    onVisible={invoiceVisble} />
                <Pix
                    open={pix}
                    setOpen={setPix}
                    onVisible={setInvoiceVisable}
                    onCloseAll={CloseAll}
                    createPay={(e, i) => CreatePayment(e, i)}
                    loading={loadingPay}
                    setLoading={setLoadingPay}
                    Link={link}
                    copy={copy}
                />
                <Cartao
                    open={cartao}
                    setOpen={setCartao}
                    onVisible={setInvoiceVisable}
                    onCloseAll={CloseAll}
                    Link={link}
                    loading={loadingPay}
                    createPay={(e, i, p) => CreatePayment(e, i, p)}
                />
                <Boleto
                    open={boleto}
                    setOpen={setBoleto}
                    onVisible={setInvoiceVisable}
                    onCloseAll={CloseAll}
                    createPay={(e, i) => CreatePayment(e, i)}
                    loading={loadingPay}
                    setLoading={setLoadingPay}
                    Link={link}
                    copy={copy}
                />

                <InfosInvoice invoice={infos} setOpen={setInfos} />
            </>
        )
    }


    if (onlyTable) {
        return (
            <TableConteiner />
        )
    }
    return (
        <Page title="Dashboard | Revenda">
            <Container>
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Bem-vindo, {user.razao_social} </Typography>
                </Box>
            </Container>
            <Container>
                <TableConteiner />
            </Container>
        </Page>
    )
}
