
export function toCashBR(value, likeArray) {
    const values = value.toString().split('.');
    const currency = values[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const cents = values.length === 2 ? (values[1].length === 1 ? values[1] + '0' : values[1]) : '00';
    if (!likeArray) {
        return `R$ ${currency},${cents}`;
    }
    return [currency, cents];
}

export function convertDate(date, type, seconds, normated) {
    if (type === 'date') {
        const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
        const day = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString();
        const year = date.getFullYear().toString();
        if (normated) {
            return `${year}-${month}-${day}`;
        }
        return `${day}/${month}/${year}`;
    } else {
        const hour = date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours().toString();
        const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes().toString();
        if (seconds) {
            const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds().toString() : date.getSeconds().toString();
            return `${hour}:${minutes}:${seconds}`;
        }
        return `${hour}:${minutes}`;
    }
}


export function getLegibleDate(date, minutes) {
    const day = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString();
    return `${day} de ${months[date.getMonth()]} de ${date.getFullYear()}${minutes ? ` às ${convertDate(date, 'hour', true)}` : ''}`;
}

export const months = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto',
    'setembro', 'outubro', 'novembro', 'dezembro']

export function welcome() {
    const hour = (new Date()).getHours();
    return (hour >= 5 && hour < 12) ? 'Bom dia' : (hour >= 12 && hour < 18 ? 'Boa tarde' : 'Boa noite');
}

export function genPassword() {
    return '123456';
}

export function toCapitalize(name) {
    return name[0].toUpperCase() + name.toLowerCase().slice(1);
}

export const ufs = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"];
export const states = {
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SP: 'São Paulo',
    SE: 'Sergipe',
    TO: 'Tocantins',
}

export function generateUUID() {
    let d = new Date().getTime();//Timestamp
    let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export const cpfMask = value => {
    if (value) {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1x.$2')
            .replace(/(\d{4})(\d)/, 'xxx.$2')
            .replace(/(\d{3})(\d{1,2})/, 'xxx-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
    }
    return
}
export const cnpjMask = (value) => {
    if (value) {
        return value
            .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    }
    return
}




export function checkEmail(email) {
    if (email.length === 0) {
        return { error: 'E-MAIL vazio, porfavor preencha o campo E-MAIL!' }
    }
    if (email.indexOf('@') == -1 || email.indexOf('.') == -1) {
        return { error: "Por favor, informe um E-MAIL válido!" }
    }
    return true;
}

export function CheckPass(password) {
    if (password.length === 0) {
        return { error: 'Senha vazia, porfavor preencha o campo SENHA!' }
    }
    if (password.length < 5) {
        return { error: 'Senha deve ter no minimo 6 caracteres' }
    }
    return true;
}




export function TestaCPF(strCPF = '') {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;
    var i = 1
    for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
}



export function validarCNPJ(cnpj) {

    let tamanho, numeros, digitos, soma, pos, resultado

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    tamanho = cnpj.length - 2
    numeros = cnpj.substring(0, tamanho);
    digitos = cnpj.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;

}



function isOverEighteen(year, month, day) {
    var now = parseInt(new Date().toISOString().slice(0, 10).replace(/-/g, ''));
    var dob = year * 10000 + month * 100 + day * 1;

    return now - dob > 180000;
}

export const minDate = () => {
    const date = new Date()
    const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
    const day = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString();
    const year = date.getFullYear().toString();
    const minDate = `${year - 18}-${month}-${day}`
    return minDate
}
