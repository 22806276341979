

import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Grid } from '@mui/material';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';



import rotta from '../assests/img/rotta.png';


const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    backgroundColor: '#141418'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: '#1B1B20'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  padding: 5,
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));



export default function Login() {
  return (
    <RootStyle title="Login | Rotta Mobile">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography
            style={{ color: '#fff', zIndex: 1, marginLeft: 7 }}
            variant="h1"
          >
            Painel
          </Typography>
          <img src={rotta} width={400} />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm" >
        <ContentStyle >
          <Grid sx={{ backgroundColor: '#1B1B20', p: 3, borderRadius: 2 }}>
            <Stack sx={{ mb: 5 }}>
              <Typography color="#fff" variant="h4" gutterBottom>
                Login
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>s */}
            </Stack>
            <LoginForm />
          </Grid>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
