import Navigation from './routes/navigation';

import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { ToastContainer } from 'react-toastify'

import { AuthProvider } from "./context/auth"

import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';


export default function App() {
  return (
    <ThemeConfig>
      <AuthProvider>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Navigation />
      </AuthProvider>
      <ToastContainer newestOnTop />
    </ThemeConfig>
  );
}
