import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, CircularProgress } from "@mui/material";

import BoletoImg from "../../../assests/img/boleto.png"
import { cnpjMask, cpfMask, toCashBR, getLegibleDate } from "../../../utils/functionTools"



const style = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

export default function Boleto({
    open,
    setOpen,
    onCloseAll,
    Link,
    copy,
    loading,
    setLoading,
    createPay
}) {

    const invoice = open

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (steps === '2') {
            setSteps("1")
            setCopy(false)
            onCloseAll()
            return
        }
        setOpen(false)
    }


    const [steps, setSteps] = useState('1')

    const [copyBar, setCopy] = useState(false)


    const Confirm = () => {
        createPay('boleto', [invoice])
    }

    useEffect(() => {
        if (Link) {
            setSteps('2')
        }
    }, [loading, Link])


    const Stap1 = () => {
        return (
            <>
                {
                 invoice &&   invoice.cobranca_id ?
                        <Box sx={{ ...style, position: 'absolute', }}>
                            <Typography
                                variant='h4'
                                component={'h2'}
                            >COMFIRMAR PAGAMENTO BOLETO </Typography>
                            <Grid
                                container
                                direction={"row"}
                                xs={12}
                                mt={2}
                                justifyContent="space-between">

                                <Grid
                                    item
                                    xs={4}
                                    p={1}
                                    bgcolor="#E8E8E8"
                                    borderRadius={2}>
                                    <img src={BoletoImg} />
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography
                                        variant='h4'
                                        component={'h2'}>{invoice && invoice.loja_fantasia}</Typography>

                                    <Typography
                                        variant='subtitle2'
                                        component={'h6'}>{invoice && invoice.loja_razao_social}</Typography>

                                    {invoice && cnpjMask(invoice.loja_cpf_cnpj.length > 11
                                        ? cnpjMask(invoice.loja_cpf_cnpj) : cpfMask(invoice.loja_cpf_cnpj))}

                                    <Typography
                                        variant='subtitle2'
                                        component={'h6'}>vencimento:{' '}
                                        {invoice &&
                                            getLegibleDate(new Date(invoice.vence_em && invoice.vence_em))}
                                    </Typography>

                                    <Grid
                                        xs={12}
                                        container
                                        direction="row">

                                        <Typography
                                            variant='subtitle1'
                                            component={'h6'}>Valor:
                                        </Typography>

                                        <Typography
                                            variant='subtitle1'
                                            sx={{
                                                padding: '2px',
                                                borderRadius: 1,
                                                marginLeft: 1
                                            }}
                                            component={'h6'}
                                            bgcolor="#02925D30"
                                            color={"#02925D"}
                                        >
                                            {toCashBR(invoice ? invoice.valor : 0)}
                                        </Typography>
                                    </Grid>


                                </Grid>
                            </Grid>
                            <Grid
                                xs={12}
                                mt={2}
                                container
                                justifyContent={"space-between"}>
                                <Button
                                    style={{ width: 200 }}
                                    onClick={handleClose}
                                    color="error">
                                    Cancelar
                                </Button>
                                <Button
                                    style={{ width: 200 }}
                                    variant="contained"
                                    disabled={loading}
                                    onClick={Confirm}>
                                    {
                                        loading ? <CircularProgress size={22} /> : "Confirmar pagamento"
                                    }
                                </Button>
                            </Grid>
                        </Box>
                        :
                        <></>
                }
            </>
        )
    }

    const Step2 = () => (
        <Box sx={{ ...style, position: 'absolute', minWidth: '50vw' }}>
            <Grid xs={12} sx={{ position: 'relative' }}>
                <Grid
                    height={"70vh"}
                    container
                    position="absolute"
                    justifyContent={"center"}
                    alignItems="center">
                    <CircularProgress size={35} />
                </Grid>

                <Typography
                    style={{ marginBottom: 25 }}
                    variant='h4'
                    color="#2A005D"
                    component={'h2'}>
                    PAGAMENTO BOLETO
                </Typography>
                <Grid zIndex={1000} height="70vh" xs={12} sx={{ position: 'relative' }}>
                    <iframe src={Link}
                        style={{
                            height: '70vh',
                            width: '100%',
                            zIndex: 1,
                            borderRadius: 10,
                            border: 'none',
                            position: 'absolute'

                        }} />
                </Grid>
                <Grid
                    xs={12}
                    mt={2}
                    justifyContent="space-between"
                    container>
                    <Button
                        color="error" sx={{ width: 200 }}
                        onClick={handleClose}
                    >FECHA</Button>
                    <Button
                        color={copyBar ? "success" : "primary"}
                        sx={{ width: 230 }}
                        variant='contained'
                        onClick={() => { setCopy(true); navigator.clipboard.writeText(copy) }}
                    >

                        <Typography
                            variant='subtitle2'
                            component={'h6'}>
                            {!copyBar && "Copiar "} codigo de barras {copyBar && " Copiado"}
                        </Typography>

                    </Button>
                </Grid>
            </Grid>
        </Box>
    )




    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {
                (() => {
                    switch (steps) {
                        case '1':
                            return Stap1()
                            break;
                        case '2':
                            return Step2()
                            break;

                        default:
                            break;
                    }
                })()
            }
        </Modal>
    );
}