import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from "@mui/material";

import { FaBarcode, FaCreditCard, BiX } from "react-icons/all";

import Pix from "../../../assests/img/logo-pix-icone-512.png"

import ModalPix from "./pix"

import { cnpjMask, cpfMask, toCashBR } from "../../../utils/functionTools"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 500,
    minHeight: 500,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

export default function MethodPayment({
    invoice,
    pix,
    setOpen,
    setPix,
    setCartao,
    cartao,
    setBoleto,
    boleto
}) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [visible, setVisble] = useState(true)


    useEffect(() => {
        if (pix || cartao || boleto) {
            setVisble(false)
            return
        }
        setVisble(true)
    }, [pix, cartao, boleto])


    return (
        <Modal
            open={invoice}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"  
        >
            <>

                {
                    visible ?
                        <Box sx={style}>
                            <Grid
                                xs={12}
                                container
                                justifyContent={"flex-end"}>
                                <Button
                                    onClick={handleClose}
                                    color="error"
                                    variant="contained"
                                    sx={{
                                        marginRight: -7,
                                        marginTop: -7,
                                        borderRadius: 2,
                                        width: 2,
                                        height: 40,
                                        padding: 0
                                    }}><BiX size={20} color="white" /></Button>
                            </Grid>
                            <Typography
                                id="modal-modal-title"
                                variant="h3"
                                component="h1">
                                FORMAS DE PAGAMENTOS
                            </Typography>

                            <Grid
                                xs={12}
                                container
                                justifyContent={"space-between"}>

                                <Typography
                                    id="modal-modal-title"
                                    color="primary"
                                    variant="h6"
                                    component="h6">
                                    {invoice && invoice.loja_fantasia}
                                </Typography>

                                <Typography
                                    id="modal-modal-title"
                                    color="primary"
                                    variant="h6"
                                    component="h6">
                                    {invoice && toCashBR(invoice.valor ? invoice.valor : 0)}
                                </Typography>
                            </Grid>
                            <Typography
                                id="modal-modal-title"
                                color="primary"
                                variant="subtitle2"
                                component="text">

                                {invoice && cnpjMask(invoice.revenda_cpf_cnpj.length > 11
                                    ? cnpjMask(invoice.revenda_cpf_cnpj) : cpfMask(invoice.loja_cpf_cnpj))}
                            </Typography>

                            {/* <Typography id="modal-modal-title" color="primary" variant="subtitle2" component="h3">
                    {invoice && invoice.loja_cpf_cnpj && invoice.loja_cpf_cnpj.length > 11 ?
                        cnpjMask(invoice.loja_cpf_cnpj) : cpfMask(invoice.loja_cpf_cnpj)}
                </Typography> */}

                            <Grid
                                xs={12}
                                mt={5}>
                                <Button
                                    onClick={() => { setPix(invoice); setVisble(false) }}
                                    color="inherit"
                                    sx={{ width: '100%' }}>

                                    <Grid
                                        container
                                        direction={"row"}
                                        alignItems="center">
                                        <img src={Pix} width={40} />
                                        <Typography
                                            variant='h5'
                                            sx={{ marginLeft: 3 }}>Pix</Typography>

                                    </Grid>
                                </Button>
                            </Grid>

                            <Grid xs={12} mt={5}>
                                <Button
                                    onClick={() => setBoleto(invoice)}
                                    color="inherit"
                                    sx={{ width: '100%' }}>
                                    <Grid
                                        container
                                        direction={"row"}
                                        alignItems="center">
                                        <FaBarcode size={40} />
                                        <Typography variant='h5' sx={{ marginLeft: 3 }}>Boleto bancário</Typography>
                                    </Grid>
                                </Button>
                            </Grid>

                            <Grid xs={12} mt={5}>
                                <Button
                                    onClick={() => { setCartao(invoice); setVisble(false) }}
                                    color="inherit" sx={{ width: '100%' }}>
                                    <Grid
                                        container
                                        direction={"row"}
                                        alignItems="center">
                                        <FaCreditCard size={40} />
                                        <Typography
                                            variant='h5'
                                            sx={{ marginLeft: 3 }}>cartão de crédito</Typography>
                                    </Grid>
                                </Button>

                            </Grid>
                            <Typography
                                id="modal-modal-title"
                                sx={{ textAlign: 'center', marginTop: 2 }}
                                color="error"
                                variant="subtitle1"
                                component="h2">
                                Atenção
                            </Typography>
                            <Typography
                                id="modal-modal-title"
                                sx={{ textAlign: 'center' }}
                                color="error"
                                variant="subtitle2"
                                component="h6">
                                para constar no nosso sistema é necessário que o pagamento seja gerado.
                                e efetuado átraves dos métodos de pagamentos disponíveis (pix, cartão de crédito, boleto bancário).
                            </Typography>
                        </Box>
                        : <></>
                }
            </>
        </Modal>
    );
}