import axios from "axios";
import { exact } from "prop-types";

export async function fetchCEPData(cep) {
    try {
        const response = await axios.get(`${window.ENV.API_URL}/cep/${cep.replace(/[^0-9]+/g, '')}/json`);
        return response.data;
    } catch {
        return null;
    }
}