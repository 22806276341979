import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, CircularProgress } from "@mui/material";

import HLPix from "../../../assests/img/pix-hotline.png"
import BgColor from "../../../assests/img/bg-color.png"
import PixImg from "../../../assests/img/logo-pix-icone-512.png"
import { cnpjMask, cpfMask, toCashBR, getLegibleDate } from "../../../utils/functionTools"

import Lottie from "lottie-react-web"

import PhonePaymentAnimate from "../../../assests/animates/phonePayment.json"

const style = {

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

export default function Pix({
    open,
    setOpen,
    onCloseAll,
    loading,
    setLoading,
    Link,
    createPay,
    copy
}) {
    const invoice = open

    const handleClose = () => {
        if (steps === '2') {
            setSteps('1')
            onCloseAll()
            return

        }
        setCopy(false)
        setSteps('1')
        setOpen(false)
    }


    const [steps, setSteps] = useState('1')

    const [pixCopy, setCopy] = useState(false)

    const gerarPagamento = () => {
        createPay('pix', [invoice])
    }



    useEffect(() => {
        if (Link) {
            setSteps('2')
        }
    }, [loading, Link])


    const Stap1 = () => {
        return (
            <>
                {invoice.cobranca_id ?
                    <Box
                        sx={{
                            ...style,
                            position: 'absolute',
                        }}>
                        <Typography
                            variant='h4'
                            component={'h2'}
                        >COMFIRMAR PAGAMENTO PIX </Typography>

                        <Grid
                            container
                            direction={"row"}
                            xs={12}
                            mt={2}
                            justifyContent="space-between">

                            <Grid
                                item
                                xs={4}
                                p={1}
                                bgcolor="#E8E8E8"
                                borderRadius={2}>

                                <img src={PixImg} />
                            </Grid>

                            <Grid item xs={7}>
                                <Typography
                                    variant='h4'
                                    component={'h2'}
                                >{invoice && invoice.loja_fantasia}</Typography>
                                <Typography
                                    variant='subtitle2'
                                    component={'h6'}>
                                    {invoice && invoice.loja_razao_social}</Typography>

                                {invoice && cnpjMask(invoice.loja_cpf_cnpj.length > 11
                                    ? cnpjMask(invoice.loja_cpf_cnpj) : cpfMask(invoice.loja_cpf_cnpj))}

                                <Typography
                                    variant='subtitle2'
                                    component={'h6'}>vencimento:{' '}
                                    {invoice && getLegibleDate(new Date(invoice.vence_em && invoice.vence_em))}
                                </Typography>
                                <Grid
                                    xs={12}
                                    container
                                    direction="row">

                                    <Typography
                                        variant='subtitle1'
                                        component={'h6'}>Valor:
                                    </Typography>
                                    <Typography
                                        variant='subtitle1'
                                        sx={{
                                            padding: '2px',
                                            borderRadius: 1,
                                            marginLeft: 1
                                        }}
                                        component={'h6'}
                                        bgcolor="#02925D30"
                                        color={"#02925D"}
                                    >
                                        {toCashBR(invoice ? invoice.valor : 0)}
                                    </Typography>
                                </Grid>


                            </Grid>
                        </Grid>
                        <Grid xs={12} container justifyContent={"space-between"}>
                            <Button style={{ width: 200 }} onClick={handleClose} color="error" >
                                Cancelar
                            </Button>
                            <Button style={{ width: 200 }} variant="contained" disabled={loading} onClick={gerarPagamento}>
                                {
                                    loading ? <CircularProgress size={22} /> : "Confirmar pagamento"
                                }
                            </Button>
                        </Grid>
                    </Box>
                    :
                    <></>
                }
            </>
        )
    }



    const Stap2 = () => (
        <Box sx={{
            ...style,
            position: 'absolute',
            overflow: 'hidden'
        }}>
            <img src={BgColor} style={{ marginTop: -45 }} />
            <Grid
                xs={12}
                mt={2}
                container
                justifyContent="space-between" >
                <Grid
                    xs={12}
                    mb={2}>
                    <p style={{
                        fontSize: 25,
                        color: '#2A005D',
                        textAlign: 'center'
                    }}>ESCANEIE O QR-CODE PARA EFETUAR O PAGAMENTO</p>
                </Grid>

                <Grid
                    item
                    xs={4}
                    p={1}
                    borderRadius={2}>

                    <Lottie
                        options={{
                            animationData: PhonePaymentAnimate,
                            loop: false
                        }}
                        width={300}
                    />

                </Grid>
                <Grid
                    container
                    xs={7}
                    justifyContent="center">

                    <img width={300} src={Link} />
                </Grid>

            </Grid>
            <Grid
                xs={12}
                container
                mt={2}
                justifyContent={"space-between"}>

                <Grid
                    xs={4}
                    container
                    justifyContent={"center"}>
                    <Button
                        color={'error'}
                        onClick={handleClose}
                        style={{ width: 200 }}
                    >
                        Fechar
                    </Button>
                </Grid>
                <Grid xs={7} container justifyContent={"center"}>
                    <Button
                        color={pixCopy ? 'success' : 'primary'}
                        onClick={() => {
                            setCopy(true); navigator.clipboard.writeText(copy)
                        }}
                        style={{ width: 200 }}
                        variant="contained" >
                        {!pixCopy && 'Copiar '}pix {pixCopy && ' Copiado'}
                    </Button>
                </Grid>

            </Grid>
        </Box>
    )

    return (

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {
                (() => {
                    switch (steps) {
                        case '1':
                            return <Stap1 />
                            break
                        case '2':
                            return <Stap2 />
                            break
                        default:
                            break;
                    }
                })()
            }

        </Modal>

    );
}


