import React, { useEffect, useState } from "react"

import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@mui/material"


import Lottie from "lottie-react-web"
import ConfigMobile from "../../assests/animates/config.json"
import Blocked from "../../assests/animates/fail.json"


import { RemoteServices } from "../../services"

import ChangePass from "./changePass"

import User from "./user"
import NewUser from "./newUser"

import "./style.css"

import Toast from "../../components/toast"

const Money = () => {

    const [paymentStatus, setPaymentStatus] = useState('')
    const [changePassView, setChangePassView] = useState(false)


    const getMe = () => {
        RemoteServices.user.retrive(1).then(r => {
            setPaymentStatus(r.app.status)
            r.app.status !== 'ATIVO' && Toast({
                textMain: 'PAINEL BLOQUEADO',
                subText: `
                Isso significa que não é possível adicionar ou liberar usuários.
                Contate o seu suporte técnico para voltar a utilizar o sistema.`,
                type: 'error',
                block: true,
                alert: true
            })
        }).catch(() => {
        })
    }

    useEffect(() => {
        getMe()
    }, [])

    const [newUser, setNewUser] = useState(false)

    return (
        <Grid xs={12} container justifyContent={"space-around"}>
            {
                paymentStatus === 'ATIVO' ? <>
                    <Grid item >
                        <Lottie
                            options={{
                                animationData: ConfigMobile
                            }}
                            width={500}
                        />

                    </Grid>
                    <Grid maxWidth={780} item>
                        <User setNewUser={e => setNewUser(e)} setChangePass={setChangePassView}/>
                    </Grid>
                </>
                    :
                    paymentStatus === 'BLOQUEADO' ?
                    <Grid>
                        <Lottie
                            options={{
                                animationData: Blocked
                            }}
                            width={500}
                        />
                        <span
                            xs={12}
                            className="block-animate"
                            variant="h3"
                        >
                            PAINEL BLOQUEADO
                        </span>
                    </Grid>
                    : <></>
            }

            <NewUser newId={newUser} close={setNewUser} />
            <ChangePass _id={changePassView}close={setChangePassView} />
        </Grid>
    )
}

export default Money;