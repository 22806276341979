import React, { useState, useEffect } from "react"
import Table from "./table"
import InfosPays from "./snack"

import {
    Tabs,
    Tab,
    Box,
    Typography,
    Modal,
    Button,
    Grid,
    CircularProgress
} from "@mui/material"

import { RemoteServices } from "../../../services"


import Toast from "../../../components/toast"

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: 2,

};



const Payments = () => {

    const [value, setValue] = useState(0);

    const [data, setData] = useState([])
    const [count, setCount] = useState(0)

    const [del, setDel] = useState(0)
    const [pay, setPay] = useState(0)

    const [page, setPage] = useState(0)


    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState('AGUARDANDO')

    const [payInfos, setPayInfos] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [loading, setLoading] = useState(false)

    const [loadingAction, setLoadingAction] = useState(false)

    const GetPays = async () => {
        setLoading(true)
        await RemoteServices.user.requireUsersPagaments({
            page: page + 1, limit: 10, status: filter, usuario: search
        }).then((r) => {
            if (r.error) {
                return
            }
            setData(r.pagamentos);
            setCount(r.contagem);
        }).catch(() => {

        })
            .finally(() => {
                setLoading(false)
            })
    }


    const markPaid = async (pagamento_id) => {
        setLoadingAction(true)
        await RemoteServices.payments.makerPaid(pagamento_id).then((r) => {
            if (r.error) {
                Toast({
                    textMain: 'Não foi possível marcar pagamento como pago!',
                    subText: r.error,
                    type: 'error'
                })
                return
            }
            Toast({
                textMain: 'Pagamento confirmado!',
                subText: 'o pagamento foi marcado como pago!'
            })
            GetPays()
            setLoadingAction(false)
        }).catch((err) => {
            Toast({
                textMain: 'Não foi possível marcar pagamento como pago!',
                subText: 'se o problema persistir contate o suporte!',
                type: 'error'
            })
            console.log(err)
        })
            .finally(() => {
                setLoadingAction(false)
            })
    }


    const cancelPaid = async (pagamento_id) => {
        setLoadingAction(true)
        await RemoteServices.payments.makerPaid(pagamento_id).then((r) => {
            if (r.error) {
                Toast({
                    textMain: 'Não foi possível cancelar pagamento!',
                    subText: r.error,
                    type: 'error'
                })
                return
            }
            Toast({
                textMain: 'Pagamento cancelado!',
                subText: 'o pagamento foi cancelado com successo!'
            })
            setDel(null)
            GetPays()
        }).catch((err) => {
            Toast({
                textMain: 'Não foi possível cancelar pagamento!',
                subText: 'se o problema persistir contate o suporte!',
                type: 'error'
            })
            console.log(err)
        })
            .finally(() => {
                setLoadingAction(false)
            })
    }



    useEffect(() => {
        GetPays()
    }, [search, filter, page])

    return (
        <>
            <Tabs value={value} onChange={handleChange}>
                <Tab label="Força de Vendas" {...a11yProps(0)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Table
                    setSearch={setSearch}
                    search={search}
                    data={data}
                    loading={loading}
                    count={count}
                    makerPay={(e) => setPay(e)}
                    cancelPay={e => setDel(e)}
                    filter={e => setFilter(e)}
                    page={page}
                    setPage={setPage}
                    ClickEvent={e => setPayInfos(e)}
                />
            </TabPanel>
            <InfosPays pay={payInfos} setPay={setPayInfos} />
            <Modal
                open={del || pay}
                onClose={() => {setDel(false) ; setPay(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"

                    sx={style}>
                    <Grid container justifyContent={"center"}>
                        <Typography variant="h3">
                            Você deseja realmente {del ? 'excluir' : 'marcar como paga'} a cobranca {del ? del : pay }
                        </Typography>
                        <Grid mt={2}>
                            <Button
                                onClick={() => {setDel(false) ; setPay(false)}}
                                style={{ width: 200 }}
                                color="error">Cancelar</Button>
                            <Button 
                            disabled={loadingAction}
                            style={{ width: 200 }} 
                            onClick={e => {
                                del ? cancelPaid(del) : markPaid(pay)
                            }} variant="contained">
                                {
                                    loadingAction ?
                                        <CircularProgress size={22} color="inherit"/>
                                        : del ? 'Excluir' : 'Marcar como paga'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default Payments