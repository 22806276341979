import React, { useEffect, useState } from "react"

import { Grid, TextField, Button, Slide, Snackbar, Typography } from "@mui/material"

import { months, toCashBR } from "../../../utils/functionTools"

import { RemoteServices } from "../../../services"


const Money = () => {

    const [isentar, setInsentar] = useState('')


    const [loading, setLoading] = useState(false)
    const [loja, setLoja] = useState({ contagem: 0, loja: { isento: false } })

    const [open, setOpen] = useState(false);
    const [transition, setTransition] = useState(undefined);

    const handleClose = () => {
        setOpen(false);
    };



    const getLoja = async (fragment, app) => {

        await RemoteServices.user.requireUsersList({
            page: 1, limit: 1, perfil: 'loja', fragment: fragment.replace(/[^0-9]/g, ''), app: 1
        })
            .then(async (e) => {
                setLoja({ contagem: e.contagem, loja: e.usuarios[0] })
                setOpen(true)
            })
            .catch((err) => { })
            .finally(() => setLoading(false))
    }


    const isent = async (id, app) => {

        setLoading(true)

        await RemoteServices.payments.isencao(
            {
                loja: id,
                app: app
            })
            .then((e) => {
                if (e) {
                    if (e.error) {
                        return
                    }
                    getLoja(isentar)
                }
            })
            .catch((err) => {
            })
            .finally(() => {
                setLoading(false)
            })

    }


    useEffect(() => {
        if (isentar.length > 10)
            getLoja(isentar)
        if (isentar.length < 10) {
            setLoja({ contagem: 0, loja: { isento: false } })
        }
    }, [isentar])


    return (
        <Grid
            mt={5}
            xs={12}
            container
            justifyContent={"center"}>
            <Grid
                xs={8}
                bgcolor="#E5E5E5"
                p={2}
                borderRadius={2}
                spacing={2}
                container>
                <TextField
                    fullWidth
                    onChange={(e) => setInsentar(e.target.value)}
                    id="outlined-basic"
                    label="Cpf/Cnpj"
                    variant="outlined" />
                <Button
                    disabled={isentar.length < 10 || loading}
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={() => isent(loja && loja.loja.id, 1)}
                    variant="contained">
                    {loja.loja && loja.loja.isento ? 'Tirar isenção' : 'Isentar'}
                </Button>
            </Grid>
            <Snackbar
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                open={open && isentar.length > 10}
                onClose={handleClose}
                TransitionComponent={Slide}
                key={'up'}
            >
                <Grid
                    minWidth={300}
                    bgcolor="#CFCFCF"
                    borderRadius={2}
                    p={2}
                >
                    {
                        loja.loja ?
                            <>
                                <Grid
                                    container
                                    sx={12}
                                    justifyContent="space-between">
                                    <Typography
                                        component="span"
                                    >Razão Social: </Typography>
                                    <Typography
                                        color="#8626D1"

                                        component="span"
                                        sx={{ ml: 1 }}>{loja && loja.loja.razao_social}</Typography>
                                </Grid>
                                <Grid
                                    container
                                    sx={12}
                                    justifyContent="space-between">
                                    <Typography
                                        component="span"
                                    >Fantasia: </Typography>
                                    <Typography
                                        component="span"
                                        color="#8626D1"

                                        sx={{ ml: 1 }}>{loja && loja.loja.nome_fantasia}</Typography>
                                </Grid>
                                <Grid
                                    container
                                    sx={12}
                                    justifyContent="space-between">
                                    <Typography
                                        component="span"
                                    >E-mail: </Typography>
                                    <Typography
                                        component="span"
                                        color="#8626D1"
                                        sx={{ ml: 1 }}>{loja && loja.loja.email}</Typography>
                                </Grid>
                                <Grid
                                    container
                                    sx={12}
                                    justifyContent="space-between">
                                    <Typography
                                        component="span"
                                    >Cpf/Cnpj: </Typography>
                                    <Typography
                                        component="span"
                                        color="#8626D1"
                                        sx={{ ml: 1 }}>{loja && loja.loja.cpf_cnpj}</Typography>
                                </Grid>
                                <Grid
                                    container
                                    sx={12}
                                    justifyContent="space-between">
                                    <Typography
                                        component="span"
                                    >Isenta: </Typography>
                                    <Typography
                                        color="#8626D1"
                                        component="span"
                                        sx={{ ml: 1 }}>{loja && !loja.loja.isento && 'Não'} Isento</Typography>
                                </Grid>
                            </>
                            :
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: "center"
                                }}
                            >Loja não Encontrada</Typography>
                    }
                </Grid>
            </Snackbar>
        </Grid>
    )
}

export default Money;