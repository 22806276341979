import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container, Grid } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

import NotFound from "../assests/animates/notFound.json"

import Lottie from "lottie-react-web"

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <RootStyle title="404 | Rotta-Mobile">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Desculpe, pagína não encontrada!
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Desculpe, não conseguimos encontrar a página que você está procurando. Talvez você tenha digitado incorretamente o URL?
              Certifique-se de verificar sua ortografia.
            </Typography>
            <Grid p={3}>
              <Lottie
                options={{
                  animationData: NotFound
                }}
              />
            </Grid>
          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Voltar para home
          </Button>
        </Box>
      </MotionContainer>
    </Container>
    </RootStyle >
  );
}
